.g-font-11 {
  font-size: 11px;
}

.g-font-12 {
  font-size: 12px;
}

.g-font-13 {
  font-size: 13px;
}

.g-font-14 {
  font-size: 14px;
}

.g-font-15 {
  font-size: 15px;
}

.g-font-16 {
  font-size: 16px;
}

.g-font-17 {
  font-size: 17px;
}

.g-font-18 {
  font-size: 18px;
}

.g-font-19 {
  font-size: 19px;
}

.g-font-20 {
  font-size: 20px;
}

.g-font-21 {
  font-size: 21px;
}

.g-font-22 {
  font-size: 22px;
}

.g-font-23 {
  font-size: 23px;
}

.g-font-24 {
  font-size: 24px;
}

.g-font-25 {
  font-size: 25px;
}

.g-font-26 {
  font-size: 26px;
}

.g-font-27 {
  font-size: 27px;
}

.g-font-28 {
  font-size: 28px;
}

.g-font-29 {
  font-size: 29px;
}

.g-font-30 {
  font-size: 30px;
}

.g-font-31 {
  font-size: 31px;
}

.g-font-32 {
  font-size: 32px;
}

.g-font-33 {
  font-size: 33px;
}

.g-font-34 {
  font-size: 34px;
}

.g-font-35 {
  font-size: 35px;
}

.g-font-36 {
  font-size: 36px;
}

.g-font-37 {
  font-size: 37px;
}

.g-font-38 {
  font-size: 38px;
}

.g-font-39 {
  font-size: 39px;
}

.g-font-40 {
  font-size: 40px;
}

.g-font-41 {
  font-size: 41px;
}

.g-font-42 {
  font-size: 42px;
}

.g-font-43 {
  font-size: 43px;
}

.g-font-44 {
  font-size: 44px;
}

.g-font-45 {
  font-size: 45px;
}

.g-font-46 {
  font-size: 46px;
}

.g-font-47 {
  font-size: 47px;
}

.g-font-48 {
  font-size: 48px;
}

.g-font-49 {
  font-size: 49px;
}

.g-font-50 {
  font-size: 50px;
}

.g-line-10 {
  line-height: 10px;
}

.g-line-11 {
  line-height: 11px;
}

.g-line-12 {
  line-height: 12px;
}

.g-line-13 {
  line-height: 13px;
}

.g-line-14 {
  line-height: 14px;
}

.g-line-15 {
  line-height: 15px;
}

.g-line-16 {
  line-height: 16px;
}

.g-line-17 {
  line-height: 17px;
}

.g-line-18 {
  line-height: 18px;
}

.g-line-19 {
  line-height: 19px;
}

.g-line-20 {
  line-height: 20px;
}

.g-line-21 {
  line-height: 21px;
}

.g-line-22 {
  line-height: 22px;
}

.g-line-23 {
  line-height: 23px;
}

.g-line-24 {
  line-height: 24px;
}

.g-line-25 {
  line-height: 25px;
}

.g-line-26 {
  line-height: 26px;
}

.g-line-27 {
  line-height: 27px;
}

.g-line-28 {
  line-height: 28px;
}

.g-line-29 {
  line-height: 29px;
}

.g-line-30 {
  line-height: 30px;
}

.g-line-31 {
  line-height: 31px;
}

.g-line-32 {
  line-height: 32px;
}

.g-line-33 {
  line-height: 33px;
}

.g-opacity-1 {
  opacity: 0.1 !important;
}

.g-opacity-2 {
  opacity: 0.2 !important;
}

.g-opacity-3 {
  opacity: 0.3 !important;
}

.g-opacity-4 {
  opacity: 0.4 !important;
}

.g-opacity-5 {
  opacity: 0.5 !important;
}

.g-opacity-6 {
  opacity: 0.6 !important;
}

.g-opacity-7 {
  opacity: 0.7 !important;
}

.g-opacity-8 {
  opacity: 0.8 !important;
}

.g-opacity-9 {
  opacity: 0.9 !important;
}

.g-opacity-10 {
  opacity: 1 !important;
}

.g-bb-E6E6E6 {
  border-bottom: 1px solid #e6e6e6;
}

.g-bt-E6E6E6 {
  border-top: 1px solid #e6e6e6;
}

.g-bl-E6E6E6 {
  border-left: 1px solid #e6e6e6;
}

.g-br-E6E6E6 {
  border-right: 1px solid #e6e6e6;
}

.g-border-E6E6E6 {
  border: 1px solid #e6e6e6;
}

.g-border2-E6E6E6 {
  border: 2px solid #e6e6e6;
}

.g-bg-E6E6E6 {
  background: #e6e6e6;
}

.g-color-E6E6E6 {
  color: #e6e6e6;
}

.g-bb-737373 {
  border-bottom: 1px solid #737373;
}

.g-bt-737373 {
  border-top: 1px solid #737373;
}

.g-bl-737373 {
  border-left: 1px solid #737373;
}

.g-br-737373 {
  border-right: 1px solid #737373;
}

.g-border-737373 {
  border: 1px solid #737373;
}

.g-border2-737373 {
  border: 2px solid #737373;
}

.g-bg-737373 {
  background: #737373;
}

.g-color-737373 {
  color: #737373;
}

.g-bb-F5F5F5 {
  border-bottom: 1px solid #f5f5f5;
}

.g-bt-F5F5F5 {
  border-top: 1px solid #f5f5f5;
}

.g-bl-F5F5F5 {
  border-left: 1px solid #f5f5f5;
}

.g-br-F5F5F5 {
  border-right: 1px solid #f5f5f5;
}

.g-border-F5F5F5 {
  border: 1px solid #f5f5f5;
}

.g-border2-F5F5F5 {
  border: 2px solid #f5f5f5;
}

.g-bg-F5F5F5 {
  background: #f5f5f5;
}

.g-color-F5F5F5 {
  color: #f5f5f5;
}

.g-bb-424242 {
  border-bottom: 1px solid #424242;
}

.g-bt-424242 {
  border-top: 1px solid #424242;
}

.g-bl-424242 {
  border-left: 1px solid #424242;
}

.g-br-424242 {
  border-right: 1px solid #424242;
}

.g-border-424242 {
  border: 1px solid #424242;
}

.g-border2-424242 {
  border: 2px solid #424242;
}

.g-bg-424242 {
  background: #424242;
}

.g-color-424242 {
  color: #424242;
}

.g-bb-2F2F2F {
  border-bottom: 1px solid #2f2f2f;
}

.g-bt-2F2F2F {
  border-top: 1px solid #2f2f2f;
}

.g-bl-2F2F2F {
  border-left: 1px solid #2f2f2f;
}

.g-br-2F2F2F {
  border-right: 1px solid #2f2f2f;
}

.g-border-2F2F2F {
  border: 1px solid #2f2f2f;
}

.g-border2-2F2F2F {
  border: 2px solid #2f2f2f;
}

.g-bg-2F2F2F {
  background: #2f2f2f;
}

.g-color-2F2F2F {
  color: #2f2f2f;
}

.g-bb-FCFCFC {
  border-bottom: 1px solid #fcfcfc;
}

.g-bt-FCFCFC {
  border-top: 1px solid #fcfcfc;
}

.g-bl-FCFCFC {
  border-left: 1px solid #fcfcfc;
}

.g-br-FCFCFC {
  border-right: 1px solid #fcfcfc;
}

.g-border-FCFCFC {
  border: 1px solid #fcfcfc;
}

.g-border2-FCFCFC {
  border: 2px solid #fcfcfc;
}

.g-bg-FCFCFC {
  background: #fcfcfc;
}

.g-color-FCFCFC {
  color: #fcfcfc;
}

.g-bb-5D5D5D {
  border-bottom: 1px solid #5d5d5d;
}

.g-bt-5D5D5D {
  border-top: 1px solid #5d5d5d;
}

.g-bl-5D5D5D {
  border-left: 1px solid #5d5d5d;
}

.g-br-5D5D5D {
  border-right: 1px solid #5d5d5d;
}

.g-border-5D5D5D {
  border: 1px solid #5d5d5d;
}

.g-border2-5D5D5D {
  border: 2px solid #5d5d5d;
}

.g-bg-5D5D5D {
  background: #5d5d5d;
}

.g-color-5D5D5D {
  color: #5d5d5d;
}

.g-bb-979797 {
  border-bottom: 1px solid #979797;
}

.g-bt-979797 {
  border-top: 1px solid #979797;
}

.g-bl-979797 {
  border-left: 1px solid #979797;
}

.g-br-979797 {
  border-right: 1px solid #979797;
}

.g-border-979797 {
  border: 1px solid #979797;
}

.g-border2-979797 {
  border: 2px solid #979797;
}

.g-bg-979797 {
  background: #979797;
}

.g-color-979797 {
  color: #979797;
}

.g-bb-C4C4C4 {
  border-bottom: 1px solid #c4c4c4;
}

.g-bt-C4C4C4 {
  border-top: 1px solid #c4c4c4;
}

.g-bl-C4C4C4 {
  border-left: 1px solid #c4c4c4;
}

.g-br-C4C4C4 {
  border-right: 1px solid #c4c4c4;
}

.g-border-C4C4C4 {
  border: 1px solid #c4c4c4;
}

.g-border2-C4C4C4 {
  border: 2px solid #c4c4c4;
}

.g-bg-C4C4C4 {
  background: #c4c4c4;
}

.g-color-C4C4C4 {
  color: #c4c4c4;
}

.g-bb-accent {
  border-bottom: 1px solid #dadada;
}

.g-bt-accent {
  border-top: 1px solid #dadada;
}

.g-bl-accent {
  border-left: 1px solid #dadada;
}

.g-br-accent {
  border-right: 1px solid #dadada;
}

.g-border-accent {
  border: 1px solid #dadada;
}

.g-border2-accent {
  border: 2px solid #dadada;
}

.g-bg-accent {
  background: #dadada;
}

.g-color-accent {
  color: #dadada;
}

.g-bb-F8F8F8 {
  border-bottom: 1px solid #f8f8f8;
}

.g-bt-F8F8F8 {
  border-top: 1px solid #f8f8f8;
}

.g-bl-F8F8F8 {
  border-left: 1px solid #f8f8f8;
}

.g-br-F8F8F8 {
  border-right: 1px solid #f8f8f8;
}

.g-border-F8F8F8 {
  border: 1px solid #f8f8f8;
}

.g-border2-F8F8F8 {
  border: 2px solid #f8f8f8;
}

.g-bg-F8F8F8 {
  background: #f8f8f8;
}

.g-color-F8F8F8 {
  color: #f8f8f8;
}

.g-bb-454545 {
  border-bottom: 1px solid #454545;
}

.g-bt-454545 {
  border-top: 1px solid #454545;
}

.g-bl-454545 {
  border-left: 1px solid #454545;
}

.g-br-454545 {
  border-right: 1px solid #454545;
}

.g-border-454545 {
  border: 1px solid #454545;
}

.g-border2-454545 {
  border: 2px solid #454545;
}

.g-bg-454545 {
  background: #454545;
}

.g-color-454545 {
  color: #454545;
}

.g-bb-AAAAAA {
  border-bottom: 1px solid #aaaaaa;
}

.g-bt-AAAAAA {
  border-top: 1px solid #aaaaaa;
}

.g-bl-AAAAAA {
  border-left: 1px solid #aaaaaa;
}

.g-br-AAAAAA {
  border-right: 1px solid #aaaaaa;
}

.g-border-AAAAAA {
  border: 1px solid #aaaaaa;
}

.g-border2-AAAAAA {
  border: 2px solid #aaaaaa;
}

.g-bg-AAAAAA {
  background: #aaaaaa;
}

.g-color-AAAAAA {
  color: #aaaaaa;
}

.g-bb-EEEEEE {
  border-bottom: 1px solid #EEEEEE;
}

.g-bt-EEEEEE {
  border-top: 1px solid #EEEEEE;
}

.g-bl-EEEEEE {
  border-left: 1px solid #EEEEEE;
}

.g-br-EEEEEE {
  border-right: 1px solid #EEEEEE;
}

.g-border-EEEEEE {
  border: 1px solid #EEEEEE;
}

.g-border2-EEEEEE {
  border: 2px solid #EEEEEE;
}

.g-bg-EEEEEE {
  background: #EEEEEE;
}

.g-color-EEEEEE {
  color: #EEEEEE;
}

.g-bb-B3B3B3 {
  border-bottom: 1px solid #B3B3B3;
}

.g-bt-B3B3B3 {
  border-top: 1px solid #B3B3B3;
}

.g-bl-B3B3B3 {
  border-left: 1px solid #B3B3B3;
}

.g-br-B3B3B3 {
  border-right: 1px solid #B3B3B3;
}

.g-border-B3B3B3 {
  border: 1px solid #B3B3B3;
}

.g-border2-B3B3B3 {
  border: 2px solid #B3B3B3;
}

.g-bg-B3B3B3 {
  background: #B3B3B3;
}

.g-color-B3B3B3 {
  color: #B3B3B3;
}

.g-bb-2B2B2B {
  border-bottom: 1px solid #2B2B2B;
}

.g-bt-2B2B2B {
  border-top: 1px solid #2B2B2B;
}

.g-bl-2B2B2B {
  border-left: 1px solid #2B2B2B;
}

.g-br-2B2B2B {
  border-right: 1px solid #2B2B2B;
}

.g-border-2B2B2B {
  border: 1px solid #2B2B2B;
}

.g-border2-2B2B2B {
  border: 2px solid #2B2B2B;
}

.g-bg-2B2B2B {
  background: #2B2B2B;
}

.g-color-2B2B2B {
  color: #2B2B2B;
}

.g-bb-FF6600 {
  border-bottom: 1px solid #FF6600;
}

.g-bt-FF6600 {
  border-top: 1px solid #FF6600;
}

.g-bl-FF6600 {
  border-left: 1px solid #FF6600;
}

.g-br-FF6600 {
  border-right: 1px solid #FF6600;
}

.g-border-FF6600 {
  border: 1px solid #FF6600;
}

.g-border2-FF6600 {
  border: 2px solid #FF6600;
}

.g-bg-FF6600 {
  background: #FF6600;
}

.g-color-FF6600 {
  color: #FF6600;
}

.g-bb-FFFFFF {
  border-bottom: 1px solid #FFFFFF;
}

.g-bt-FFFFFF {
  border-top: 1px solid #FFFFFF;
}

.g-bl-FFFFFF {
  border-left: 1px solid #FFFFFF;
}

.g-br-FFFFFF {
  border-right: 1px solid #FFFFFF;
}

.g-border-FFFFFF {
  border: 1px solid #FFFFFF;
}

.g-border2-FFFFFF {
  border: 2px solid #FFFFFF;
}

.g-bg-FFFFFF {
  background: #FFFFFF;
}

.g-color-FFFFFF {
  color: #FFFFFF;
}

.g-bb-light {
  border-bottom: 1px solid #F5F5F5;
}

.g-bt-light {
  border-top: 1px solid #F5F5F5;
}

.g-bl-light {
  border-left: 1px solid #F5F5F5;
}

.g-br-light {
  border-right: 1px solid #F5F5F5;
}

.g-border-light {
  border: 1px solid #F5F5F5;
}

.g-border2-light {
  border: 2px solid #F5F5F5;
}

.g-bg-light {
  background: #F5F5F5;
}

.g-color-light {
  color: #F5F5F5;
}

.g-bb-BBBBBB {
  border-bottom: 1px solid #BBBBBB;
}

.g-bt-BBBBBB {
  border-top: 1px solid #BBBBBB;
}

.g-bl-BBBBBB {
  border-left: 1px solid #BBBBBB;
}

.g-br-BBBBBB {
  border-right: 1px solid #BBBBBB;
}

.g-border-BBBBBB {
  border: 1px solid #BBBBBB;
}

.g-border2-BBBBBB {
  border: 2px solid #BBBBBB;
}

.g-bg-BBBBBB {
  background: #BBBBBB;
}

.g-color-BBBBBB {
  color: #BBBBBB;
}

.g-bb-969696 {
  border-bottom: 1px solid #969696;
}

.g-bt-969696 {
  border-top: 1px solid #969696;
}

.g-bl-969696 {
  border-left: 1px solid #969696;
}

.g-br-969696 {
  border-right: 1px solid #969696;
}

.g-border-969696 {
  border: 1px solid #969696;
}

.g-border2-969696 {
  border: 2px solid #969696;
}

.g-bg-969696 {
  background: #969696;
}

.g-color-969696 {
  color: #969696;
}

.g-bb-F5F5F9 {
  border-bottom: 1px solid #F5F5F9;
}

.g-bt-F5F5F9 {
  border-top: 1px solid #F5F5F9;
}

.g-bl-F5F5F9 {
  border-left: 1px solid #F5F5F9;
}

.g-br-F5F5F9 {
  border-right: 1px solid #F5F5F9;
}

.g-border-F5F5F9 {
  border: 1px solid #F5F5F9;
}

.g-border2-F5F5F9 {
  border: 2px solid #F5F5F9;
}

.g-bg-F5F5F9 {
  background: #F5F5F9;
}

.g-color-F5F5F9 {
  color: #F5F5F9;
}

.g-bb-DADADA {
  border-bottom: 1px solid #DADADA;
}

.g-bt-DADADA {
  border-top: 1px solid #DADADA;
}

.g-bl-DADADA {
  border-left: 1px solid #DADADA;
}

.g-br-DADADA {
  border-right: 1px solid #DADADA;
}

.g-border-DADADA {
  border: 1px solid #DADADA;
}

.g-border2-DADADA {
  border: 2px solid #DADADA;
}

.g-bg-DADADA {
  background: #DADADA;
}

.g-color-DADADA {
  color: #DADADA;
}

.g-bb-CCCCCC {
  border-bottom: 1px solid #CCCCCC;
}

.g-bt-CCCCCC {
  border-top: 1px solid #CCCCCC;
}

.g-bl-CCCCCC {
  border-left: 1px solid #CCCCCC;
}

.g-br-CCCCCC {
  border-right: 1px solid #CCCCCC;
}

.g-border-CCCCCC {
  border: 1px solid #CCCCCC;
}

.g-border2-CCCCCC {
  border: 2px solid #CCCCCC;
}

.g-bg-CCCCCC {
  background: #CCCCCC;
}

.g-color-CCCCCC {
  color: #CCCCCC;
}

.g-w-per-1 {
  width: 1%;
}

.g-maxw-per-1 {
  max-width: 1%;
}

.g-minw-per-1 {
  min-width: 1%;
}

.g-h-per-1 {
  height: 1%;
}

.g-maxh-per-1 {
  max-height: 1%;
}

.g-minh-per-1 {
  min-height: 1%;
}

.g-w-per-2 {
  width: 2%;
}

.g-maxw-per-2 {
  max-width: 2%;
}

.g-minw-per-2 {
  min-width: 2%;
}

.g-h-per-2 {
  height: 2%;
}

.g-maxh-per-2 {
  max-height: 2%;
}

.g-minh-per-2 {
  min-height: 2%;
}

.g-w-per-3 {
  width: 3%;
}

.g-maxw-per-3 {
  max-width: 3%;
}

.g-minw-per-3 {
  min-width: 3%;
}

.g-h-per-3 {
  height: 3%;
}

.g-maxh-per-3 {
  max-height: 3%;
}

.g-minh-per-3 {
  min-height: 3%;
}

.g-w-per-4 {
  width: 4%;
}

.g-maxw-per-4 {
  max-width: 4%;
}

.g-minw-per-4 {
  min-width: 4%;
}

.g-h-per-4 {
  height: 4%;
}

.g-maxh-per-4 {
  max-height: 4%;
}

.g-minh-per-4 {
  min-height: 4%;
}

.g-w-per-5 {
  width: 5%;
}

.g-maxw-per-5 {
  max-width: 5%;
}

.g-minw-per-5 {
  min-width: 5%;
}

.g-h-per-5 {
  height: 5%;
}

.g-maxh-per-5 {
  max-height: 5%;
}

.g-minh-per-5 {
  min-height: 5%;
}

.g-w-per-6 {
  width: 6%;
}

.g-maxw-per-6 {
  max-width: 6%;
}

.g-minw-per-6 {
  min-width: 6%;
}

.g-h-per-6 {
  height: 6%;
}

.g-maxh-per-6 {
  max-height: 6%;
}

.g-minh-per-6 {
  min-height: 6%;
}

.g-w-per-7 {
  width: 7%;
}

.g-maxw-per-7 {
  max-width: 7%;
}

.g-minw-per-7 {
  min-width: 7%;
}

.g-h-per-7 {
  height: 7%;
}

.g-maxh-per-7 {
  max-height: 7%;
}

.g-minh-per-7 {
  min-height: 7%;
}

.g-w-per-8 {
  width: 8%;
}

.g-maxw-per-8 {
  max-width: 8%;
}

.g-minw-per-8 {
  min-width: 8%;
}

.g-h-per-8 {
  height: 8%;
}

.g-maxh-per-8 {
  max-height: 8%;
}

.g-minh-per-8 {
  min-height: 8%;
}

.g-w-per-9 {
  width: 9%;
}

.g-maxw-per-9 {
  max-width: 9%;
}

.g-minw-per-9 {
  min-width: 9%;
}

.g-h-per-9 {
  height: 9%;
}

.g-maxh-per-9 {
  max-height: 9%;
}

.g-minh-per-9 {
  min-height: 9%;
}

.g-w-per-10 {
  width: 10%;
}

.g-maxw-per-10 {
  max-width: 10%;
}

.g-minw-per-10 {
  min-width: 10%;
}

.g-h-per-10 {
  height: 10%;
}

.g-maxh-per-10 {
  max-height: 10%;
}

.g-minh-per-10 {
  min-height: 10%;
}

.g-w-per-11 {
  width: 11%;
}

.g-maxw-per-11 {
  max-width: 11%;
}

.g-minw-per-11 {
  min-width: 11%;
}

.g-h-per-11 {
  height: 11%;
}

.g-maxh-per-11 {
  max-height: 11%;
}

.g-minh-per-11 {
  min-height: 11%;
}

.g-w-per-12 {
  width: 12%;
}

.g-maxw-per-12 {
  max-width: 12%;
}

.g-minw-per-12 {
  min-width: 12%;
}

.g-h-per-12 {
  height: 12%;
}

.g-maxh-per-12 {
  max-height: 12%;
}

.g-minh-per-12 {
  min-height: 12%;
}

.g-w-per-13 {
  width: 13%;
}

.g-maxw-per-13 {
  max-width: 13%;
}

.g-minw-per-13 {
  min-width: 13%;
}

.g-h-per-13 {
  height: 13%;
}

.g-maxh-per-13 {
  max-height: 13%;
}

.g-minh-per-13 {
  min-height: 13%;
}

.g-w-per-14 {
  width: 14%;
}

.g-maxw-per-14 {
  max-width: 14%;
}

.g-minw-per-14 {
  min-width: 14%;
}

.g-h-per-14 {
  height: 14%;
}

.g-maxh-per-14 {
  max-height: 14%;
}

.g-minh-per-14 {
  min-height: 14%;
}

.g-w-per-15 {
  width: 15%;
}

.g-maxw-per-15 {
  max-width: 15%;
}

.g-minw-per-15 {
  min-width: 15%;
}

.g-h-per-15 {
  height: 15%;
}

.g-maxh-per-15 {
  max-height: 15%;
}

.g-minh-per-15 {
  min-height: 15%;
}

.g-w-per-16 {
  width: 16%;
}

.g-maxw-per-16 {
  max-width: 16%;
}

.g-minw-per-16 {
  min-width: 16%;
}

.g-h-per-16 {
  height: 16%;
}

.g-maxh-per-16 {
  max-height: 16%;
}

.g-minh-per-16 {
  min-height: 16%;
}

.g-w-per-17 {
  width: 17%;
}

.g-maxw-per-17 {
  max-width: 17%;
}

.g-minw-per-17 {
  min-width: 17%;
}

.g-h-per-17 {
  height: 17%;
}

.g-maxh-per-17 {
  max-height: 17%;
}

.g-minh-per-17 {
  min-height: 17%;
}

.g-w-per-18 {
  width: 18%;
}

.g-maxw-per-18 {
  max-width: 18%;
}

.g-minw-per-18 {
  min-width: 18%;
}

.g-h-per-18 {
  height: 18%;
}

.g-maxh-per-18 {
  max-height: 18%;
}

.g-minh-per-18 {
  min-height: 18%;
}

.g-w-per-19 {
  width: 19%;
}

.g-maxw-per-19 {
  max-width: 19%;
}

.g-minw-per-19 {
  min-width: 19%;
}

.g-h-per-19 {
  height: 19%;
}

.g-maxh-per-19 {
  max-height: 19%;
}

.g-minh-per-19 {
  min-height: 19%;
}

.g-w-per-20 {
  width: 20%;
}

.g-maxw-per-20 {
  max-width: 20%;
}

.g-minw-per-20 {
  min-width: 20%;
}

.g-h-per-20 {
  height: 20%;
}

.g-maxh-per-20 {
  max-height: 20%;
}

.g-minh-per-20 {
  min-height: 20%;
}

.g-w-per-21 {
  width: 21%;
}

.g-maxw-per-21 {
  max-width: 21%;
}

.g-minw-per-21 {
  min-width: 21%;
}

.g-h-per-21 {
  height: 21%;
}

.g-maxh-per-21 {
  max-height: 21%;
}

.g-minh-per-21 {
  min-height: 21%;
}

.g-w-per-22 {
  width: 22%;
}

.g-maxw-per-22 {
  max-width: 22%;
}

.g-minw-per-22 {
  min-width: 22%;
}

.g-h-per-22 {
  height: 22%;
}

.g-maxh-per-22 {
  max-height: 22%;
}

.g-minh-per-22 {
  min-height: 22%;
}

.g-w-per-23 {
  width: 23%;
}

.g-maxw-per-23 {
  max-width: 23%;
}

.g-minw-per-23 {
  min-width: 23%;
}

.g-h-per-23 {
  height: 23%;
}

.g-maxh-per-23 {
  max-height: 23%;
}

.g-minh-per-23 {
  min-height: 23%;
}

.g-w-per-24 {
  width: 24%;
}

.g-maxw-per-24 {
  max-width: 24%;
}

.g-minw-per-24 {
  min-width: 24%;
}

.g-h-per-24 {
  height: 24%;
}

.g-maxh-per-24 {
  max-height: 24%;
}

.g-minh-per-24 {
  min-height: 24%;
}

.g-w-per-25 {
  width: 25%;
}

.g-maxw-per-25 {
  max-width: 25%;
}

.g-minw-per-25 {
  min-width: 25%;
}

.g-h-per-25 {
  height: 25%;
}

.g-maxh-per-25 {
  max-height: 25%;
}

.g-minh-per-25 {
  min-height: 25%;
}

.g-w-per-26 {
  width: 26%;
}

.g-maxw-per-26 {
  max-width: 26%;
}

.g-minw-per-26 {
  min-width: 26%;
}

.g-h-per-26 {
  height: 26%;
}

.g-maxh-per-26 {
  max-height: 26%;
}

.g-minh-per-26 {
  min-height: 26%;
}

.g-w-per-27 {
  width: 27%;
}

.g-maxw-per-27 {
  max-width: 27%;
}

.g-minw-per-27 {
  min-width: 27%;
}

.g-h-per-27 {
  height: 27%;
}

.g-maxh-per-27 {
  max-height: 27%;
}

.g-minh-per-27 {
  min-height: 27%;
}

.g-w-per-28 {
  width: 28%;
}

.g-maxw-per-28 {
  max-width: 28%;
}

.g-minw-per-28 {
  min-width: 28%;
}

.g-h-per-28 {
  height: 28%;
}

.g-maxh-per-28 {
  max-height: 28%;
}

.g-minh-per-28 {
  min-height: 28%;
}

.g-w-per-29 {
  width: 29%;
}

.g-maxw-per-29 {
  max-width: 29%;
}

.g-minw-per-29 {
  min-width: 29%;
}

.g-h-per-29 {
  height: 29%;
}

.g-maxh-per-29 {
  max-height: 29%;
}

.g-minh-per-29 {
  min-height: 29%;
}

.g-w-per-30 {
  width: 30%;
}

.g-maxw-per-30 {
  max-width: 30%;
}

.g-minw-per-30 {
  min-width: 30%;
}

.g-h-per-30 {
  height: 30%;
}

.g-maxh-per-30 {
  max-height: 30%;
}

.g-minh-per-30 {
  min-height: 30%;
}

.g-w-per-31 {
  width: 31%;
}

.g-maxw-per-31 {
  max-width: 31%;
}

.g-minw-per-31 {
  min-width: 31%;
}

.g-h-per-31 {
  height: 31%;
}

.g-maxh-per-31 {
  max-height: 31%;
}

.g-minh-per-31 {
  min-height: 31%;
}

.g-w-per-32 {
  width: 32%;
}

.g-maxw-per-32 {
  max-width: 32%;
}

.g-minw-per-32 {
  min-width: 32%;
}

.g-h-per-32 {
  height: 32%;
}

.g-maxh-per-32 {
  max-height: 32%;
}

.g-minh-per-32 {
  min-height: 32%;
}

.g-w-per-33 {
  width: 33%;
}

.g-maxw-per-33 {
  max-width: 33%;
}

.g-minw-per-33 {
  min-width: 33%;
}

.g-h-per-33 {
  height: 33%;
}

.g-maxh-per-33 {
  max-height: 33%;
}

.g-minh-per-33 {
  min-height: 33%;
}

.g-w-per-34 {
  width: 34%;
}

.g-maxw-per-34 {
  max-width: 34%;
}

.g-minw-per-34 {
  min-width: 34%;
}

.g-h-per-34 {
  height: 34%;
}

.g-maxh-per-34 {
  max-height: 34%;
}

.g-minh-per-34 {
  min-height: 34%;
}

.g-w-per-35 {
  width: 35%;
}

.g-maxw-per-35 {
  max-width: 35%;
}

.g-minw-per-35 {
  min-width: 35%;
}

.g-h-per-35 {
  height: 35%;
}

.g-maxh-per-35 {
  max-height: 35%;
}

.g-minh-per-35 {
  min-height: 35%;
}

.g-w-per-36 {
  width: 36%;
}

.g-maxw-per-36 {
  max-width: 36%;
}

.g-minw-per-36 {
  min-width: 36%;
}

.g-h-per-36 {
  height: 36%;
}

.g-maxh-per-36 {
  max-height: 36%;
}

.g-minh-per-36 {
  min-height: 36%;
}

.g-w-per-37 {
  width: 37%;
}

.g-maxw-per-37 {
  max-width: 37%;
}

.g-minw-per-37 {
  min-width: 37%;
}

.g-h-per-37 {
  height: 37%;
}

.g-maxh-per-37 {
  max-height: 37%;
}

.g-minh-per-37 {
  min-height: 37%;
}

.g-w-per-38 {
  width: 38%;
}

.g-maxw-per-38 {
  max-width: 38%;
}

.g-minw-per-38 {
  min-width: 38%;
}

.g-h-per-38 {
  height: 38%;
}

.g-maxh-per-38 {
  max-height: 38%;
}

.g-minh-per-38 {
  min-height: 38%;
}

.g-w-per-39 {
  width: 39%;
}

.g-maxw-per-39 {
  max-width: 39%;
}

.g-minw-per-39 {
  min-width: 39%;
}

.g-h-per-39 {
  height: 39%;
}

.g-maxh-per-39 {
  max-height: 39%;
}

.g-minh-per-39 {
  min-height: 39%;
}

.g-w-per-40 {
  width: 40%;
}

.g-maxw-per-40 {
  max-width: 40%;
}

.g-minw-per-40 {
  min-width: 40%;
}

.g-h-per-40 {
  height: 40%;
}

.g-maxh-per-40 {
  max-height: 40%;
}

.g-minh-per-40 {
  min-height: 40%;
}

.g-w-per-41 {
  width: 41%;
}

.g-maxw-per-41 {
  max-width: 41%;
}

.g-minw-per-41 {
  min-width: 41%;
}

.g-h-per-41 {
  height: 41%;
}

.g-maxh-per-41 {
  max-height: 41%;
}

.g-minh-per-41 {
  min-height: 41%;
}

.g-w-per-42 {
  width: 42%;
}

.g-maxw-per-42 {
  max-width: 42%;
}

.g-minw-per-42 {
  min-width: 42%;
}

.g-h-per-42 {
  height: 42%;
}

.g-maxh-per-42 {
  max-height: 42%;
}

.g-minh-per-42 {
  min-height: 42%;
}

.g-w-per-43 {
  width: 43%;
}

.g-maxw-per-43 {
  max-width: 43%;
}

.g-minw-per-43 {
  min-width: 43%;
}

.g-h-per-43 {
  height: 43%;
}

.g-maxh-per-43 {
  max-height: 43%;
}

.g-minh-per-43 {
  min-height: 43%;
}

.g-w-per-44 {
  width: 44%;
}

.g-maxw-per-44 {
  max-width: 44%;
}

.g-minw-per-44 {
  min-width: 44%;
}

.g-h-per-44 {
  height: 44%;
}

.g-maxh-per-44 {
  max-height: 44%;
}

.g-minh-per-44 {
  min-height: 44%;
}

.g-w-per-45 {
  width: 45%;
}

.g-maxw-per-45 {
  max-width: 45%;
}

.g-minw-per-45 {
  min-width: 45%;
}

.g-h-per-45 {
  height: 45%;
}

.g-maxh-per-45 {
  max-height: 45%;
}

.g-minh-per-45 {
  min-height: 45%;
}

.g-w-per-46 {
  width: 46%;
}

.g-maxw-per-46 {
  max-width: 46%;
}

.g-minw-per-46 {
  min-width: 46%;
}

.g-h-per-46 {
  height: 46%;
}

.g-maxh-per-46 {
  max-height: 46%;
}

.g-minh-per-46 {
  min-height: 46%;
}

.g-w-per-47 {
  width: 47%;
}

.g-maxw-per-47 {
  max-width: 47%;
}

.g-minw-per-47 {
  min-width: 47%;
}

.g-h-per-47 {
  height: 47%;
}

.g-maxh-per-47 {
  max-height: 47%;
}

.g-minh-per-47 {
  min-height: 47%;
}

.g-w-per-48 {
  width: 48%;
}

.g-maxw-per-48 {
  max-width: 48%;
}

.g-minw-per-48 {
  min-width: 48%;
}

.g-h-per-48 {
  height: 48%;
}

.g-maxh-per-48 {
  max-height: 48%;
}

.g-minh-per-48 {
  min-height: 48%;
}

.g-w-per-49 {
  width: 49%;
}

.g-maxw-per-49 {
  max-width: 49%;
}

.g-minw-per-49 {
  min-width: 49%;
}

.g-h-per-49 {
  height: 49%;
}

.g-maxh-per-49 {
  max-height: 49%;
}

.g-minh-per-49 {
  min-height: 49%;
}

.g-w-per-50 {
  width: 50%;
}

.g-maxw-per-50 {
  max-width: 50%;
}

.g-minw-per-50 {
  min-width: 50%;
}

.g-h-per-50 {
  height: 50%;
}

.g-maxh-per-50 {
  max-height: 50%;
}

.g-minh-per-50 {
  min-height: 50%;
}

.g-w-per-51 {
  width: 51%;
}

.g-maxw-per-51 {
  max-width: 51%;
}

.g-minw-per-51 {
  min-width: 51%;
}

.g-h-per-51 {
  height: 51%;
}

.g-maxh-per-51 {
  max-height: 51%;
}

.g-minh-per-51 {
  min-height: 51%;
}

.g-w-per-52 {
  width: 52%;
}

.g-maxw-per-52 {
  max-width: 52%;
}

.g-minw-per-52 {
  min-width: 52%;
}

.g-h-per-52 {
  height: 52%;
}

.g-maxh-per-52 {
  max-height: 52%;
}

.g-minh-per-52 {
  min-height: 52%;
}

.g-w-per-53 {
  width: 53%;
}

.g-maxw-per-53 {
  max-width: 53%;
}

.g-minw-per-53 {
  min-width: 53%;
}

.g-h-per-53 {
  height: 53%;
}

.g-maxh-per-53 {
  max-height: 53%;
}

.g-minh-per-53 {
  min-height: 53%;
}

.g-w-per-54 {
  width: 54%;
}

.g-maxw-per-54 {
  max-width: 54%;
}

.g-minw-per-54 {
  min-width: 54%;
}

.g-h-per-54 {
  height: 54%;
}

.g-maxh-per-54 {
  max-height: 54%;
}

.g-minh-per-54 {
  min-height: 54%;
}

.g-w-per-55 {
  width: 55%;
}

.g-maxw-per-55 {
  max-width: 55%;
}

.g-minw-per-55 {
  min-width: 55%;
}

.g-h-per-55 {
  height: 55%;
}

.g-maxh-per-55 {
  max-height: 55%;
}

.g-minh-per-55 {
  min-height: 55%;
}

.g-w-per-56 {
  width: 56%;
}

.g-maxw-per-56 {
  max-width: 56%;
}

.g-minw-per-56 {
  min-width: 56%;
}

.g-h-per-56 {
  height: 56%;
}

.g-maxh-per-56 {
  max-height: 56%;
}

.g-minh-per-56 {
  min-height: 56%;
}

.g-w-per-57 {
  width: 57%;
}

.g-maxw-per-57 {
  max-width: 57%;
}

.g-minw-per-57 {
  min-width: 57%;
}

.g-h-per-57 {
  height: 57%;
}

.g-maxh-per-57 {
  max-height: 57%;
}

.g-minh-per-57 {
  min-height: 57%;
}

.g-w-per-58 {
  width: 58%;
}

.g-maxw-per-58 {
  max-width: 58%;
}

.g-minw-per-58 {
  min-width: 58%;
}

.g-h-per-58 {
  height: 58%;
}

.g-maxh-per-58 {
  max-height: 58%;
}

.g-minh-per-58 {
  min-height: 58%;
}

.g-w-per-59 {
  width: 59%;
}

.g-maxw-per-59 {
  max-width: 59%;
}

.g-minw-per-59 {
  min-width: 59%;
}

.g-h-per-59 {
  height: 59%;
}

.g-maxh-per-59 {
  max-height: 59%;
}

.g-minh-per-59 {
  min-height: 59%;
}

.g-w-per-60 {
  width: 60%;
}

.g-maxw-per-60 {
  max-width: 60%;
}

.g-minw-per-60 {
  min-width: 60%;
}

.g-h-per-60 {
  height: 60%;
}

.g-maxh-per-60 {
  max-height: 60%;
}

.g-minh-per-60 {
  min-height: 60%;
}

.g-w-per-61 {
  width: 61%;
}

.g-maxw-per-61 {
  max-width: 61%;
}

.g-minw-per-61 {
  min-width: 61%;
}

.g-h-per-61 {
  height: 61%;
}

.g-maxh-per-61 {
  max-height: 61%;
}

.g-minh-per-61 {
  min-height: 61%;
}

.g-w-per-62 {
  width: 62%;
}

.g-maxw-per-62 {
  max-width: 62%;
}

.g-minw-per-62 {
  min-width: 62%;
}

.g-h-per-62 {
  height: 62%;
}

.g-maxh-per-62 {
  max-height: 62%;
}

.g-minh-per-62 {
  min-height: 62%;
}

.g-w-per-63 {
  width: 63%;
}

.g-maxw-per-63 {
  max-width: 63%;
}

.g-minw-per-63 {
  min-width: 63%;
}

.g-h-per-63 {
  height: 63%;
}

.g-maxh-per-63 {
  max-height: 63%;
}

.g-minh-per-63 {
  min-height: 63%;
}

.g-w-per-64 {
  width: 64%;
}

.g-maxw-per-64 {
  max-width: 64%;
}

.g-minw-per-64 {
  min-width: 64%;
}

.g-h-per-64 {
  height: 64%;
}

.g-maxh-per-64 {
  max-height: 64%;
}

.g-minh-per-64 {
  min-height: 64%;
}

.g-w-per-65 {
  width: 65%;
}

.g-maxw-per-65 {
  max-width: 65%;
}

.g-minw-per-65 {
  min-width: 65%;
}

.g-h-per-65 {
  height: 65%;
}

.g-maxh-per-65 {
  max-height: 65%;
}

.g-minh-per-65 {
  min-height: 65%;
}

.g-w-per-66 {
  width: 66%;
}

.g-maxw-per-66 {
  max-width: 66%;
}

.g-minw-per-66 {
  min-width: 66%;
}

.g-h-per-66 {
  height: 66%;
}

.g-maxh-per-66 {
  max-height: 66%;
}

.g-minh-per-66 {
  min-height: 66%;
}

.g-w-per-67 {
  width: 67%;
}

.g-maxw-per-67 {
  max-width: 67%;
}

.g-minw-per-67 {
  min-width: 67%;
}

.g-h-per-67 {
  height: 67%;
}

.g-maxh-per-67 {
  max-height: 67%;
}

.g-minh-per-67 {
  min-height: 67%;
}

.g-w-per-68 {
  width: 68%;
}

.g-maxw-per-68 {
  max-width: 68%;
}

.g-minw-per-68 {
  min-width: 68%;
}

.g-h-per-68 {
  height: 68%;
}

.g-maxh-per-68 {
  max-height: 68%;
}

.g-minh-per-68 {
  min-height: 68%;
}

.g-w-per-69 {
  width: 69%;
}

.g-maxw-per-69 {
  max-width: 69%;
}

.g-minw-per-69 {
  min-width: 69%;
}

.g-h-per-69 {
  height: 69%;
}

.g-maxh-per-69 {
  max-height: 69%;
}

.g-minh-per-69 {
  min-height: 69%;
}

.g-w-per-70 {
  width: 70%;
}

.g-maxw-per-70 {
  max-width: 70%;
}

.g-minw-per-70 {
  min-width: 70%;
}

.g-h-per-70 {
  height: 70%;
}

.g-maxh-per-70 {
  max-height: 70%;
}

.g-minh-per-70 {
  min-height: 70%;
}

.g-w-per-71 {
  width: 71%;
}

.g-maxw-per-71 {
  max-width: 71%;
}

.g-minw-per-71 {
  min-width: 71%;
}

.g-h-per-71 {
  height: 71%;
}

.g-maxh-per-71 {
  max-height: 71%;
}

.g-minh-per-71 {
  min-height: 71%;
}

.g-w-per-72 {
  width: 72%;
}

.g-maxw-per-72 {
  max-width: 72%;
}

.g-minw-per-72 {
  min-width: 72%;
}

.g-h-per-72 {
  height: 72%;
}

.g-maxh-per-72 {
  max-height: 72%;
}

.g-minh-per-72 {
  min-height: 72%;
}

.g-w-per-73 {
  width: 73%;
}

.g-maxw-per-73 {
  max-width: 73%;
}

.g-minw-per-73 {
  min-width: 73%;
}

.g-h-per-73 {
  height: 73%;
}

.g-maxh-per-73 {
  max-height: 73%;
}

.g-minh-per-73 {
  min-height: 73%;
}

.g-w-per-74 {
  width: 74%;
}

.g-maxw-per-74 {
  max-width: 74%;
}

.g-minw-per-74 {
  min-width: 74%;
}

.g-h-per-74 {
  height: 74%;
}

.g-maxh-per-74 {
  max-height: 74%;
}

.g-minh-per-74 {
  min-height: 74%;
}

.g-w-per-75 {
  width: 75%;
}

.g-maxw-per-75 {
  max-width: 75%;
}

.g-minw-per-75 {
  min-width: 75%;
}

.g-h-per-75 {
  height: 75%;
}

.g-maxh-per-75 {
  max-height: 75%;
}

.g-minh-per-75 {
  min-height: 75%;
}

.g-w-per-76 {
  width: 76%;
}

.g-maxw-per-76 {
  max-width: 76%;
}

.g-minw-per-76 {
  min-width: 76%;
}

.g-h-per-76 {
  height: 76%;
}

.g-maxh-per-76 {
  max-height: 76%;
}

.g-minh-per-76 {
  min-height: 76%;
}

.g-w-per-77 {
  width: 77%;
}

.g-maxw-per-77 {
  max-width: 77%;
}

.g-minw-per-77 {
  min-width: 77%;
}

.g-h-per-77 {
  height: 77%;
}

.g-maxh-per-77 {
  max-height: 77%;
}

.g-minh-per-77 {
  min-height: 77%;
}

.g-w-per-78 {
  width: 78%;
}

.g-maxw-per-78 {
  max-width: 78%;
}

.g-minw-per-78 {
  min-width: 78%;
}

.g-h-per-78 {
  height: 78%;
}

.g-maxh-per-78 {
  max-height: 78%;
}

.g-minh-per-78 {
  min-height: 78%;
}

.g-w-per-79 {
  width: 79%;
}

.g-maxw-per-79 {
  max-width: 79%;
}

.g-minw-per-79 {
  min-width: 79%;
}

.g-h-per-79 {
  height: 79%;
}

.g-maxh-per-79 {
  max-height: 79%;
}

.g-minh-per-79 {
  min-height: 79%;
}

.g-w-per-80 {
  width: 80%;
}

.g-maxw-per-80 {
  max-width: 80%;
}

.g-minw-per-80 {
  min-width: 80%;
}

.g-h-per-80 {
  height: 80%;
}

.g-maxh-per-80 {
  max-height: 80%;
}

.g-minh-per-80 {
  min-height: 80%;
}

.g-w-per-81 {
  width: 81%;
}

.g-maxw-per-81 {
  max-width: 81%;
}

.g-minw-per-81 {
  min-width: 81%;
}

.g-h-per-81 {
  height: 81%;
}

.g-maxh-per-81 {
  max-height: 81%;
}

.g-minh-per-81 {
  min-height: 81%;
}

.g-w-per-82 {
  width: 82%;
}

.g-maxw-per-82 {
  max-width: 82%;
}

.g-minw-per-82 {
  min-width: 82%;
}

.g-h-per-82 {
  height: 82%;
}

.g-maxh-per-82 {
  max-height: 82%;
}

.g-minh-per-82 {
  min-height: 82%;
}

.g-w-per-83 {
  width: 83%;
}

.g-maxw-per-83 {
  max-width: 83%;
}

.g-minw-per-83 {
  min-width: 83%;
}

.g-h-per-83 {
  height: 83%;
}

.g-maxh-per-83 {
  max-height: 83%;
}

.g-minh-per-83 {
  min-height: 83%;
}

.g-w-per-84 {
  width: 84%;
}

.g-maxw-per-84 {
  max-width: 84%;
}

.g-minw-per-84 {
  min-width: 84%;
}

.g-h-per-84 {
  height: 84%;
}

.g-maxh-per-84 {
  max-height: 84%;
}

.g-minh-per-84 {
  min-height: 84%;
}

.g-w-per-85 {
  width: 85%;
}

.g-maxw-per-85 {
  max-width: 85%;
}

.g-minw-per-85 {
  min-width: 85%;
}

.g-h-per-85 {
  height: 85%;
}

.g-maxh-per-85 {
  max-height: 85%;
}

.g-minh-per-85 {
  min-height: 85%;
}

.g-w-per-86 {
  width: 86%;
}

.g-maxw-per-86 {
  max-width: 86%;
}

.g-minw-per-86 {
  min-width: 86%;
}

.g-h-per-86 {
  height: 86%;
}

.g-maxh-per-86 {
  max-height: 86%;
}

.g-minh-per-86 {
  min-height: 86%;
}

.g-w-per-87 {
  width: 87%;
}

.g-maxw-per-87 {
  max-width: 87%;
}

.g-minw-per-87 {
  min-width: 87%;
}

.g-h-per-87 {
  height: 87%;
}

.g-maxh-per-87 {
  max-height: 87%;
}

.g-minh-per-87 {
  min-height: 87%;
}

.g-w-per-88 {
  width: 88%;
}

.g-maxw-per-88 {
  max-width: 88%;
}

.g-minw-per-88 {
  min-width: 88%;
}

.g-h-per-88 {
  height: 88%;
}

.g-maxh-per-88 {
  max-height: 88%;
}

.g-minh-per-88 {
  min-height: 88%;
}

.g-w-per-89 {
  width: 89%;
}

.g-maxw-per-89 {
  max-width: 89%;
}

.g-minw-per-89 {
  min-width: 89%;
}

.g-h-per-89 {
  height: 89%;
}

.g-maxh-per-89 {
  max-height: 89%;
}

.g-minh-per-89 {
  min-height: 89%;
}

.g-w-per-90 {
  width: 90%;
}

.g-maxw-per-90 {
  max-width: 90%;
}

.g-minw-per-90 {
  min-width: 90%;
}

.g-h-per-90 {
  height: 90%;
}

.g-maxh-per-90 {
  max-height: 90%;
}

.g-minh-per-90 {
  min-height: 90%;
}

.g-w-per-91 {
  width: 91%;
}

.g-maxw-per-91 {
  max-width: 91%;
}

.g-minw-per-91 {
  min-width: 91%;
}

.g-h-per-91 {
  height: 91%;
}

.g-maxh-per-91 {
  max-height: 91%;
}

.g-minh-per-91 {
  min-height: 91%;
}

.g-w-per-92 {
  width: 92%;
}

.g-maxw-per-92 {
  max-width: 92%;
}

.g-minw-per-92 {
  min-width: 92%;
}

.g-h-per-92 {
  height: 92%;
}

.g-maxh-per-92 {
  max-height: 92%;
}

.g-minh-per-92 {
  min-height: 92%;
}

.g-w-per-93 {
  width: 93%;
}

.g-maxw-per-93 {
  max-width: 93%;
}

.g-minw-per-93 {
  min-width: 93%;
}

.g-h-per-93 {
  height: 93%;
}

.g-maxh-per-93 {
  max-height: 93%;
}

.g-minh-per-93 {
  min-height: 93%;
}

.g-w-per-94 {
  width: 94%;
}

.g-maxw-per-94 {
  max-width: 94%;
}

.g-minw-per-94 {
  min-width: 94%;
}

.g-h-per-94 {
  height: 94%;
}

.g-maxh-per-94 {
  max-height: 94%;
}

.g-minh-per-94 {
  min-height: 94%;
}

.g-w-per-95 {
  width: 95%;
}

.g-maxw-per-95 {
  max-width: 95%;
}

.g-minw-per-95 {
  min-width: 95%;
}

.g-h-per-95 {
  height: 95%;
}

.g-maxh-per-95 {
  max-height: 95%;
}

.g-minh-per-95 {
  min-height: 95%;
}

.g-w-per-96 {
  width: 96%;
}

.g-maxw-per-96 {
  max-width: 96%;
}

.g-minw-per-96 {
  min-width: 96%;
}

.g-h-per-96 {
  height: 96%;
}

.g-maxh-per-96 {
  max-height: 96%;
}

.g-minh-per-96 {
  min-height: 96%;
}

.g-w-per-97 {
  width: 97%;
}

.g-maxw-per-97 {
  max-width: 97%;
}

.g-minw-per-97 {
  min-width: 97%;
}

.g-h-per-97 {
  height: 97%;
}

.g-maxh-per-97 {
  max-height: 97%;
}

.g-minh-per-97 {
  min-height: 97%;
}

.g-w-per-98 {
  width: 98%;
}

.g-maxw-per-98 {
  max-width: 98%;
}

.g-minw-per-98 {
  min-width: 98%;
}

.g-h-per-98 {
  height: 98%;
}

.g-maxh-per-98 {
  max-height: 98%;
}

.g-minh-per-98 {
  min-height: 98%;
}

.g-w-per-99 {
  width: 99%;
}

.g-maxw-per-99 {
  max-width: 99%;
}

.g-minw-per-99 {
  min-width: 99%;
}

.g-h-per-99 {
  height: 99%;
}

.g-maxh-per-99 {
  max-height: 99%;
}

.g-minh-per-99 {
  min-height: 99%;
}

.g-w-0 {
  width: 0 !important;
}

.g-min-w-0 {
  min-width: 0 !important;
}

.g-max-w-0 {
  max-width: 0 !important;
}

.g-h-0 {
  height: 0;
}

.g-min-h-0 {
  min-height: 0;
}

.g-ma-0 {
  margin: 0;
}

.g-mh-0 {
  margin-left: 0;
  margin-right: 0;
}

.g-mv-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.g-mt-0 {
  margin-top: 0;
}

.g-mt-n0 {
  margin-top: 0;
}

.g-mb-0 {
  margin-bottom: 0;
}

.g-mb-n0 {
  margin-bottom: 0;
}

.g-ml-0 {
  margin-left: 0;
}

.g-ml-n0 {
  margin-left: 0;
}

.g-mr-0 {
  margin-right: 0;
}

.g-mr-n0 {
  margin-right: 0;
}

.g-pa-0 {
  padding: 0;
}

.g-pt-0 {
  padding-top: 0;
}

.g-pb-0 {
  padding-bottom: 0;
}

.g-pl-0 {
  padding-left: 0;
}

.g-pr-0 {
  padding-right: 0;
}

.g-ph-0 {
  padding-left: 0;
  padding-right: 0;
}

.g-pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.g-rounded-p0 {
  border-radius: 0;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 0;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 0;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 0;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 0;
}

.g-w-2 {
  width: 2px !important;
}

.g-min-w-2 {
  min-width: 2px !important;
}

.g-max-w-2 {
  max-width: 2px !important;
}

.g-h-2 {
  height: 2px;
}

.g-min-h-2 {
  min-height: 2px;
}

.g-ma-2 {
  margin: 2px;
}

.g-mh-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.g-mv-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.g-mt-2 {
  margin-top: 2px;
}

.g-mt-n2 {
  margin-top: -2px;
}

.g-mb-2 {
  margin-bottom: 2px;
}

.g-mb-n2 {
  margin-bottom: -2px;
}

.g-ml-2 {
  margin-left: 2px;
}

.g-ml-n2 {
  margin-left: -2px;
}

.g-mr-2 {
  margin-right: 2px;
}

.g-mr-n2 {
  margin-right: -2px;
}

.g-pa-2 {
  padding: 2px;
}

.g-pt-2 {
  padding-top: 2px;
}

.g-pb-2 {
  padding-bottom: 2px;
}

.g-pl-2 {
  padding-left: 2px;
}

.g-pr-2 {
  padding-right: 2px;
}

.g-ph-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.g-pv-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.g-rounded-p2 {
  border-radius: 2px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 2px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 2px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 2px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 2px;
}

.g-w-3 {
  width: 3px !important;
}

.g-min-w-3 {
  min-width: 3px !important;
}

.g-max-w-3 {
  max-width: 3px !important;
}

.g-h-3 {
  height: 3px;
}

.g-min-h-3 {
  min-height: 3px;
}

.g-ma-3 {
  margin: 3px;
}

.g-mh-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.g-mv-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.g-mt-3 {
  margin-top: 3px;
}

.g-mt-n3 {
  margin-top: -3px;
}

.g-mb-3 {
  margin-bottom: 3px;
}

.g-mb-n3 {
  margin-bottom: -3px;
}

.g-ml-3 {
  margin-left: 3px;
}

.g-ml-n3 {
  margin-left: -3px;
}

.g-mr-3 {
  margin-right: 3px;
}

.g-mr-n3 {
  margin-right: -3px;
}

.g-pa-3 {
  padding: 3px;
}

.g-pt-3 {
  padding-top: 3px;
}

.g-pb-3 {
  padding-bottom: 3px;
}

.g-pl-3 {
  padding-left: 3px;
}

.g-pr-3 {
  padding-right: 3px;
}

.g-ph-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.g-pv-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.g-rounded-p3 {
  border-radius: 3px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 3px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 3px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 3px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 3px;
}

.g-w-4 {
  width: 4px !important;
}

.g-min-w-4 {
  min-width: 4px !important;
}

.g-max-w-4 {
  max-width: 4px !important;
}

.g-h-4 {
  height: 4px;
}

.g-min-h-4 {
  min-height: 4px;
}

.g-ma-4 {
  margin: 4px;
}

.g-mh-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.g-mv-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.g-mt-4 {
  margin-top: 4px;
}

.g-mt-n4 {
  margin-top: -4px;
}

.g-mb-4 {
  margin-bottom: 4px;
}

.g-mb-n4 {
  margin-bottom: -4px;
}

.g-ml-4 {
  margin-left: 4px;
}

.g-ml-n4 {
  margin-left: -4px;
}

.g-mr-4 {
  margin-right: 4px;
}

.g-mr-n4 {
  margin-right: -4px;
}

.g-pa-4 {
  padding: 4px;
}

.g-pt-4 {
  padding-top: 4px;
}

.g-pb-4 {
  padding-bottom: 4px;
}

.g-pl-4 {
  padding-left: 4px;
}

.g-pr-4 {
  padding-right: 4px;
}

.g-ph-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.g-pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.g-rounded-p4 {
  border-radius: 4px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 4px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 4px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 4px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 4px;
}

.g-w-5 {
  width: 5px !important;
}

.g-min-w-5 {
  min-width: 5px !important;
}

.g-max-w-5 {
  max-width: 5px !important;
}

.g-h-5 {
  height: 5px;
}

.g-min-h-5 {
  min-height: 5px;
}

.g-ma-5 {
  margin: 5px;
}

.g-mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.g-mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.g-mt-5 {
  margin-top: 5px;
}

.g-mt-n5 {
  margin-top: -5px;
}

.g-mb-5 {
  margin-bottom: 5px;
}

.g-mb-n5 {
  margin-bottom: -5px;
}

.g-ml-5 {
  margin-left: 5px;
}

.g-ml-n5 {
  margin-left: -5px;
}

.g-mr-5 {
  margin-right: 5px;
}

.g-mr-n5 {
  margin-right: -5px;
}

.g-pa-5 {
  padding: 5px;
}

.g-pt-5 {
  padding-top: 5px;
}

.g-pb-5 {
  padding-bottom: 5px;
}

.g-pl-5 {
  padding-left: 5px;
}

.g-pr-5 {
  padding-right: 5px;
}

.g-ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.g-pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.g-rounded-p5 {
  border-radius: 5px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 5px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 5px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 5px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 5px;
}

.g-w-6 {
  width: 6px !important;
}

.g-min-w-6 {
  min-width: 6px !important;
}

.g-max-w-6 {
  max-width: 6px !important;
}

.g-h-6 {
  height: 6px;
}

.g-min-h-6 {
  min-height: 6px;
}

.g-ma-6 {
  margin: 6px;
}

.g-mh-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.g-mv-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.g-mt-6 {
  margin-top: 6px;
}

.g-mt-n6 {
  margin-top: -6px;
}

.g-mb-6 {
  margin-bottom: 6px;
}

.g-mb-n6 {
  margin-bottom: -6px;
}

.g-ml-6 {
  margin-left: 6px;
}

.g-ml-n6 {
  margin-left: -6px;
}

.g-mr-6 {
  margin-right: 6px;
}

.g-mr-n6 {
  margin-right: -6px;
}

.g-pa-6 {
  padding: 6px;
}

.g-pt-6 {
  padding-top: 6px;
}

.g-pb-6 {
  padding-bottom: 6px;
}

.g-pl-6 {
  padding-left: 6px;
}

.g-pr-6 {
  padding-right: 6px;
}

.g-ph-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.g-pv-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.g-rounded-p6 {
  border-radius: 6px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 6px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 6px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 6px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 6px;
}

.g-w-8 {
  width: 8px !important;
}

.g-min-w-8 {
  min-width: 8px !important;
}

.g-max-w-8 {
  max-width: 8px !important;
}

.g-h-8 {
  height: 8px;
}

.g-min-h-8 {
  min-height: 8px;
}

.g-ma-8 {
  margin: 8px;
}

.g-mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.g-mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.g-mt-8 {
  margin-top: 8px;
}

.g-mt-n8 {
  margin-top: -8px;
}

.g-mb-8 {
  margin-bottom: 8px;
}

.g-mb-n8 {
  margin-bottom: -8px;
}

.g-ml-8 {
  margin-left: 8px;
}

.g-ml-n8 {
  margin-left: -8px;
}

.g-mr-8 {
  margin-right: 8px;
}

.g-mr-n8 {
  margin-right: -8px;
}

.g-pa-8 {
  padding: 8px;
}

.g-pt-8 {
  padding-top: 8px;
}

.g-pb-8 {
  padding-bottom: 8px;
}

.g-pl-8 {
  padding-left: 8px;
}

.g-pr-8 {
  padding-right: 8px;
}

.g-ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.g-pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.g-rounded-p8 {
  border-radius: 8px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 8px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 8px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 8px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 8px;
}

.g-w-9 {
  width: 9px !important;
}

.g-min-w-9 {
  min-width: 9px !important;
}

.g-max-w-9 {
  max-width: 9px !important;
}

.g-h-9 {
  height: 9px;
}

.g-min-h-9 {
  min-height: 9px;
}

.g-ma-9 {
  margin: 9px;
}

.g-mh-9 {
  margin-left: 9px;
  margin-right: 9px;
}

.g-mv-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.g-mt-9 {
  margin-top: 9px;
}

.g-mt-n9 {
  margin-top: -9px;
}

.g-mb-9 {
  margin-bottom: 9px;
}

.g-mb-n9 {
  margin-bottom: -9px;
}

.g-ml-9 {
  margin-left: 9px;
}

.g-ml-n9 {
  margin-left: -9px;
}

.g-mr-9 {
  margin-right: 9px;
}

.g-mr-n9 {
  margin-right: -9px;
}

.g-pa-9 {
  padding: 9px;
}

.g-pt-9 {
  padding-top: 9px;
}

.g-pb-9 {
  padding-bottom: 9px;
}

.g-pl-9 {
  padding-left: 9px;
}

.g-pr-9 {
  padding-right: 9px;
}

.g-ph-9 {
  padding-left: 9px;
  padding-right: 9px;
}

.g-pv-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.g-rounded-p9 {
  border-radius: 9px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 9px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 9px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 9px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 9px;
}

.g-w-10 {
  width: 10px !important;
}

.g-min-w-10 {
  min-width: 10px !important;
}

.g-max-w-10 {
  max-width: 10px !important;
}

.g-h-10 {
  height: 10px;
}

.g-min-h-10 {
  min-height: 10px;
}

.g-ma-10 {
  margin: 10px;
}

.g-mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.g-mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.g-mt-10 {
  margin-top: 10px;
}

.g-mt-n10 {
  margin-top: -10px;
}

.g-mb-10 {
  margin-bottom: 10px;
}

.g-mb-n10 {
  margin-bottom: -10px;
}

.g-ml-10 {
  margin-left: 10px;
}

.g-ml-n10 {
  margin-left: -10px;
}

.g-mr-10 {
  margin-right: 10px;
}

.g-mr-n10 {
  margin-right: -10px;
}

.g-pa-10 {
  padding: 10px;
}

.g-pt-10 {
  padding-top: 10px;
}

.g-pb-10 {
  padding-bottom: 10px;
}

.g-pl-10 {
  padding-left: 10px;
}

.g-pr-10 {
  padding-right: 10px;
}

.g-ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.g-pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.g-rounded-p10 {
  border-radius: 10px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 10px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 10px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 10px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 10px;
}

.g-w-11 {
  width: 11px !important;
}

.g-min-w-11 {
  min-width: 11px !important;
}

.g-max-w-11 {
  max-width: 11px !important;
}

.g-h-11 {
  height: 11px;
}

.g-min-h-11 {
  min-height: 11px;
}

.g-ma-11 {
  margin: 11px;
}

.g-mh-11 {
  margin-left: 11px;
  margin-right: 11px;
}

.g-mv-11 {
  margin-top: 11px;
  margin-bottom: 11px;
}

.g-mt-11 {
  margin-top: 11px;
}

.g-mt-n11 {
  margin-top: -11px;
}

.g-mb-11 {
  margin-bottom: 11px;
}

.g-mb-n11 {
  margin-bottom: -11px;
}

.g-ml-11 {
  margin-left: 11px;
}

.g-ml-n11 {
  margin-left: -11px;
}

.g-mr-11 {
  margin-right: 11px;
}

.g-mr-n11 {
  margin-right: -11px;
}

.g-pa-11 {
  padding: 11px;
}

.g-pt-11 {
  padding-top: 11px;
}

.g-pb-11 {
  padding-bottom: 11px;
}

.g-pl-11 {
  padding-left: 11px;
}

.g-pr-11 {
  padding-right: 11px;
}

.g-ph-11 {
  padding-left: 11px;
  padding-right: 11px;
}

.g-pv-11 {
  padding-top: 11px;
  padding-bottom: 11px;
}

.g-rounded-p11 {
  border-radius: 11px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 11px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 11px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 11px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 11px;
}

.g-w-13 {
  width: 13px !important;
}

.g-min-w-13 {
  min-width: 13px !important;
}

.g-max-w-13 {
  max-width: 13px !important;
}

.g-h-13 {
  height: 13px;
}

.g-min-h-13 {
  min-height: 13px;
}

.g-ma-13 {
  margin: 13px;
}

.g-mh-13 {
  margin-left: 13px;
  margin-right: 13px;
}

.g-mv-13 {
  margin-top: 13px;
  margin-bottom: 13px;
}

.g-mt-13 {
  margin-top: 13px;
}

.g-mt-n13 {
  margin-top: -13px;
}

.g-mb-13 {
  margin-bottom: 13px;
}

.g-mb-n13 {
  margin-bottom: -13px;
}

.g-ml-13 {
  margin-left: 13px;
}

.g-ml-n13 {
  margin-left: -13px;
}

.g-mr-13 {
  margin-right: 13px;
}

.g-mr-n13 {
  margin-right: -13px;
}

.g-pa-13 {
  padding: 13px;
}

.g-pt-13 {
  padding-top: 13px;
}

.g-pb-13 {
  padding-bottom: 13px;
}

.g-pl-13 {
  padding-left: 13px;
}

.g-pr-13 {
  padding-right: 13px;
}

.g-ph-13 {
  padding-left: 13px;
  padding-right: 13px;
}

.g-pv-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}

.g-rounded-p13 {
  border-radius: 13px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 13px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 13px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 13px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 13px;
}

.g-w-15 {
  width: 15px !important;
}

.g-min-w-15 {
  min-width: 15px !important;
}

.g-max-w-15 {
  max-width: 15px !important;
}

.g-h-15 {
  height: 15px;
}

.g-min-h-15 {
  min-height: 15px;
}

.g-ma-15 {
  margin: 15px;
}

.g-mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.g-mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.g-mt-15 {
  margin-top: 15px;
}

.g-mt-n15 {
  margin-top: -15px;
}

.g-mb-15 {
  margin-bottom: 15px;
}

.g-mb-n15 {
  margin-bottom: -15px;
}

.g-ml-15 {
  margin-left: 15px;
}

.g-ml-n15 {
  margin-left: -15px;
}

.g-mr-15 {
  margin-right: 15px;
}

.g-mr-n15 {
  margin-right: -15px;
}

.g-pa-15 {
  padding: 15px;
}

.g-pt-15 {
  padding-top: 15px;
}

.g-pb-15 {
  padding-bottom: 15px;
}

.g-pl-15 {
  padding-left: 15px;
}

.g-pr-15 {
  padding-right: 15px;
}

.g-ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.g-pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.g-rounded-p15 {
  border-radius: 15px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 15px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 15px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 15px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 15px;
}

.g-w-16 {
  width: 16px !important;
}

.g-min-w-16 {
  min-width: 16px !important;
}

.g-max-w-16 {
  max-width: 16px !important;
}

.g-h-16 {
  height: 16px;
}

.g-min-h-16 {
  min-height: 16px;
}

.g-ma-16 {
  margin: 16px;
}

.g-mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.g-mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.g-mt-16 {
  margin-top: 16px;
}

.g-mt-n16 {
  margin-top: -16px;
}

.g-mb-16 {
  margin-bottom: 16px;
}

.g-mb-n16 {
  margin-bottom: -16px;
}

.g-ml-16 {
  margin-left: 16px;
}

.g-ml-n16 {
  margin-left: -16px;
}

.g-mr-16 {
  margin-right: 16px;
}

.g-mr-n16 {
  margin-right: -16px;
}

.g-pa-16 {
  padding: 16px;
}

.g-pt-16 {
  padding-top: 16px;
}

.g-pb-16 {
  padding-bottom: 16px;
}

.g-pl-16 {
  padding-left: 16px;
}

.g-pr-16 {
  padding-right: 16px;
}

.g-ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.g-pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.g-rounded-p16 {
  border-radius: 16px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 16px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 16px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 16px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 16px;
}

.g-w-18 {
  width: 18px !important;
}

.g-min-w-18 {
  min-width: 18px !important;
}

.g-max-w-18 {
  max-width: 18px !important;
}

.g-h-18 {
  height: 18px;
}

.g-min-h-18 {
  min-height: 18px;
}

.g-ma-18 {
  margin: 18px;
}

.g-mh-18 {
  margin-left: 18px;
  margin-right: 18px;
}

.g-mv-18 {
  margin-top: 18px;
  margin-bottom: 18px;
}

.g-mt-18 {
  margin-top: 18px;
}

.g-mt-n18 {
  margin-top: -18px;
}

.g-mb-18 {
  margin-bottom: 18px;
}

.g-mb-n18 {
  margin-bottom: -18px;
}

.g-ml-18 {
  margin-left: 18px;
}

.g-ml-n18 {
  margin-left: -18px;
}

.g-mr-18 {
  margin-right: 18px;
}

.g-mr-n18 {
  margin-right: -18px;
}

.g-pa-18 {
  padding: 18px;
}

.g-pt-18 {
  padding-top: 18px;
}

.g-pb-18 {
  padding-bottom: 18px;
}

.g-pl-18 {
  padding-left: 18px;
}

.g-pr-18 {
  padding-right: 18px;
}

.g-ph-18 {
  padding-left: 18px;
  padding-right: 18px;
}

.g-pv-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.g-rounded-p18 {
  border-radius: 18px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 18px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 18px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 18px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 18px;
}

.g-w-20 {
  width: 20px !important;
}

.g-min-w-20 {
  min-width: 20px !important;
}

.g-max-w-20 {
  max-width: 20px !important;
}

.g-h-20 {
  height: 20px;
}

.g-min-h-20 {
  min-height: 20px;
}

.g-ma-20 {
  margin: 20px;
}

.g-mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.g-mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.g-mt-20 {
  margin-top: 20px;
}

.g-mt-n20 {
  margin-top: -20px;
}

.g-mb-20 {
  margin-bottom: 20px;
}

.g-mb-n20 {
  margin-bottom: -20px;
}

.g-ml-20 {
  margin-left: 20px;
}

.g-ml-n20 {
  margin-left: -20px;
}

.g-mr-20 {
  margin-right: 20px;
}

.g-mr-n20 {
  margin-right: -20px;
}

.g-pa-20 {
  padding: 20px;
}

.g-pt-20 {
  padding-top: 20px;
}

.g-pb-20 {
  padding-bottom: 20px;
}

.g-pl-20 {
  padding-left: 20px;
}

.g-pr-20 {
  padding-right: 20px;
}

.g-ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.g-pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.g-rounded-p20 {
  border-radius: 20px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 20px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 20px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 20px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 20px;
}

.g-w-22 {
  width: 22px !important;
}

.g-min-w-22 {
  min-width: 22px !important;
}

.g-max-w-22 {
  max-width: 22px !important;
}

.g-h-22 {
  height: 22px;
}

.g-min-h-22 {
  min-height: 22px;
}

.g-ma-22 {
  margin: 22px;
}

.g-mh-22 {
  margin-left: 22px;
  margin-right: 22px;
}

.g-mv-22 {
  margin-top: 22px;
  margin-bottom: 22px;
}

.g-mt-22 {
  margin-top: 22px;
}

.g-mt-n22 {
  margin-top: -22px;
}

.g-mb-22 {
  margin-bottom: 22px;
}

.g-mb-n22 {
  margin-bottom: -22px;
}

.g-ml-22 {
  margin-left: 22px;
}

.g-ml-n22 {
  margin-left: -22px;
}

.g-mr-22 {
  margin-right: 22px;
}

.g-mr-n22 {
  margin-right: -22px;
}

.g-pa-22 {
  padding: 22px;
}

.g-pt-22 {
  padding-top: 22px;
}

.g-pb-22 {
  padding-bottom: 22px;
}

.g-pl-22 {
  padding-left: 22px;
}

.g-pr-22 {
  padding-right: 22px;
}

.g-ph-22 {
  padding-left: 22px;
  padding-right: 22px;
}

.g-pv-22 {
  padding-top: 22px;
  padding-bottom: 22px;
}

.g-rounded-p22 {
  border-radius: 22px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 22px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 22px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 22px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 22px;
}

.g-w-25 {
  width: 25px !important;
}

.g-min-w-25 {
  min-width: 25px !important;
}

.g-max-w-25 {
  max-width: 25px !important;
}

.g-h-25 {
  height: 25px;
}

.g-min-h-25 {
  min-height: 25px;
}

.g-ma-25 {
  margin: 25px;
}

.g-mh-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.g-mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.g-mt-25 {
  margin-top: 25px;
}

.g-mt-n25 {
  margin-top: -25px;
}

.g-mb-25 {
  margin-bottom: 25px;
}

.g-mb-n25 {
  margin-bottom: -25px;
}

.g-ml-25 {
  margin-left: 25px;
}

.g-ml-n25 {
  margin-left: -25px;
}

.g-mr-25 {
  margin-right: 25px;
}

.g-mr-n25 {
  margin-right: -25px;
}

.g-pa-25 {
  padding: 25px;
}

.g-pt-25 {
  padding-top: 25px;
}

.g-pb-25 {
  padding-bottom: 25px;
}

.g-pl-25 {
  padding-left: 25px;
}

.g-pr-25 {
  padding-right: 25px;
}

.g-ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.g-pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.g-rounded-p25 {
  border-radius: 25px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 25px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 25px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 25px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 25px;
}

.g-w-27 {
  width: 27px !important;
}

.g-min-w-27 {
  min-width: 27px !important;
}

.g-max-w-27 {
  max-width: 27px !important;
}

.g-h-27 {
  height: 27px;
}

.g-min-h-27 {
  min-height: 27px;
}

.g-ma-27 {
  margin: 27px;
}

.g-mh-27 {
  margin-left: 27px;
  margin-right: 27px;
}

.g-mv-27 {
  margin-top: 27px;
  margin-bottom: 27px;
}

.g-mt-27 {
  margin-top: 27px;
}

.g-mt-n27 {
  margin-top: -27px;
}

.g-mb-27 {
  margin-bottom: 27px;
}

.g-mb-n27 {
  margin-bottom: -27px;
}

.g-ml-27 {
  margin-left: 27px;
}

.g-ml-n27 {
  margin-left: -27px;
}

.g-mr-27 {
  margin-right: 27px;
}

.g-mr-n27 {
  margin-right: -27px;
}

.g-pa-27 {
  padding: 27px;
}

.g-pt-27 {
  padding-top: 27px;
}

.g-pb-27 {
  padding-bottom: 27px;
}

.g-pl-27 {
  padding-left: 27px;
}

.g-pr-27 {
  padding-right: 27px;
}

.g-ph-27 {
  padding-left: 27px;
  padding-right: 27px;
}

.g-pv-27 {
  padding-top: 27px;
  padding-bottom: 27px;
}

.g-rounded-p27 {
  border-radius: 27px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 27px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 27px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 27px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 27px;
}

.g-w-30 {
  width: 30px !important;
}

.g-min-w-30 {
  min-width: 30px !important;
}

.g-max-w-30 {
  max-width: 30px !important;
}

.g-h-30 {
  height: 30px;
}

.g-min-h-30 {
  min-height: 30px;
}

.g-ma-30 {
  margin: 30px;
}

.g-mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.g-mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.g-mt-30 {
  margin-top: 30px;
}

.g-mt-n30 {
  margin-top: -30px;
}

.g-mb-30 {
  margin-bottom: 30px;
}

.g-mb-n30 {
  margin-bottom: -30px;
}

.g-ml-30 {
  margin-left: 30px;
}

.g-ml-n30 {
  margin-left: -30px;
}

.g-mr-30 {
  margin-right: 30px;
}

.g-mr-n30 {
  margin-right: -30px;
}

.g-pa-30 {
  padding: 30px;
}

.g-pt-30 {
  padding-top: 30px;
}

.g-pb-30 {
  padding-bottom: 30px;
}

.g-pl-30 {
  padding-left: 30px;
}

.g-pr-30 {
  padding-right: 30px;
}

.g-ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.g-pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.g-rounded-p30 {
  border-radius: 30px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 30px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 30px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 30px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 30px;
}

.g-w-32 {
  width: 32px !important;
}

.g-min-w-32 {
  min-width: 32px !important;
}

.g-max-w-32 {
  max-width: 32px !important;
}

.g-h-32 {
  height: 32px;
}

.g-min-h-32 {
  min-height: 32px;
}

.g-ma-32 {
  margin: 32px;
}

.g-mh-32 {
  margin-left: 32px;
  margin-right: 32px;
}

.g-mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.g-mt-32 {
  margin-top: 32px;
}

.g-mt-n32 {
  margin-top: -32px;
}

.g-mb-32 {
  margin-bottom: 32px;
}

.g-mb-n32 {
  margin-bottom: -32px;
}

.g-ml-32 {
  margin-left: 32px;
}

.g-ml-n32 {
  margin-left: -32px;
}

.g-mr-32 {
  margin-right: 32px;
}

.g-mr-n32 {
  margin-right: -32px;
}

.g-pa-32 {
  padding: 32px;
}

.g-pt-32 {
  padding-top: 32px;
}

.g-pb-32 {
  padding-bottom: 32px;
}

.g-pl-32 {
  padding-left: 32px;
}

.g-pr-32 {
  padding-right: 32px;
}

.g-ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.g-pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.g-rounded-p32 {
  border-radius: 32px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 32px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 32px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 32px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 32px;
}

.g-w-34 {
  width: 34px !important;
}

.g-min-w-34 {
  min-width: 34px !important;
}

.g-max-w-34 {
  max-width: 34px !important;
}

.g-h-34 {
  height: 34px;
}

.g-min-h-34 {
  min-height: 34px;
}

.g-ma-34 {
  margin: 34px;
}

.g-mh-34 {
  margin-left: 34px;
  margin-right: 34px;
}

.g-mv-34 {
  margin-top: 34px;
  margin-bottom: 34px;
}

.g-mt-34 {
  margin-top: 34px;
}

.g-mt-n34 {
  margin-top: -34px;
}

.g-mb-34 {
  margin-bottom: 34px;
}

.g-mb-n34 {
  margin-bottom: -34px;
}

.g-ml-34 {
  margin-left: 34px;
}

.g-ml-n34 {
  margin-left: -34px;
}

.g-mr-34 {
  margin-right: 34px;
}

.g-mr-n34 {
  margin-right: -34px;
}

.g-pa-34 {
  padding: 34px;
}

.g-pt-34 {
  padding-top: 34px;
}

.g-pb-34 {
  padding-bottom: 34px;
}

.g-pl-34 {
  padding-left: 34px;
}

.g-pr-34 {
  padding-right: 34px;
}

.g-ph-34 {
  padding-left: 34px;
  padding-right: 34px;
}

.g-pv-34 {
  padding-top: 34px;
  padding-bottom: 34px;
}

.g-rounded-p34 {
  border-radius: 34px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 34px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 34px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 34px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 34px;
}

.g-w-38 {
  width: 38px !important;
}

.g-min-w-38 {
  min-width: 38px !important;
}

.g-max-w-38 {
  max-width: 38px !important;
}

.g-h-38 {
  height: 38px;
}

.g-min-h-38 {
  min-height: 38px;
}

.g-ma-38 {
  margin: 38px;
}

.g-mh-38 {
  margin-left: 38px;
  margin-right: 38px;
}

.g-mv-38 {
  margin-top: 38px;
  margin-bottom: 38px;
}

.g-mt-38 {
  margin-top: 38px;
}

.g-mt-n38 {
  margin-top: -38px;
}

.g-mb-38 {
  margin-bottom: 38px;
}

.g-mb-n38 {
  margin-bottom: -38px;
}

.g-ml-38 {
  margin-left: 38px;
}

.g-ml-n38 {
  margin-left: -38px;
}

.g-mr-38 {
  margin-right: 38px;
}

.g-mr-n38 {
  margin-right: -38px;
}

.g-pa-38 {
  padding: 38px;
}

.g-pt-38 {
  padding-top: 38px;
}

.g-pb-38 {
  padding-bottom: 38px;
}

.g-pl-38 {
  padding-left: 38px;
}

.g-pr-38 {
  padding-right: 38px;
}

.g-ph-38 {
  padding-left: 38px;
  padding-right: 38px;
}

.g-pv-38 {
  padding-top: 38px;
  padding-bottom: 38px;
}

.g-rounded-p38 {
  border-radius: 38px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 38px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 38px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 38px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 38px;
}

.g-w-40 {
  width: 40px !important;
}

.g-min-w-40 {
  min-width: 40px !important;
}

.g-max-w-40 {
  max-width: 40px !important;
}

.g-h-40 {
  height: 40px;
}

.g-min-h-40 {
  min-height: 40px;
}

.g-ma-40 {
  margin: 40px;
}

.g-mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.g-mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.g-mt-40 {
  margin-top: 40px;
}

.g-mt-n40 {
  margin-top: -40px;
}

.g-mb-40 {
  margin-bottom: 40px;
}

.g-mb-n40 {
  margin-bottom: -40px;
}

.g-ml-40 {
  margin-left: 40px;
}

.g-ml-n40 {
  margin-left: -40px;
}

.g-mr-40 {
  margin-right: 40px;
}

.g-mr-n40 {
  margin-right: -40px;
}

.g-pa-40 {
  padding: 40px;
}

.g-pt-40 {
  padding-top: 40px;
}

.g-pb-40 {
  padding-bottom: 40px;
}

.g-pl-40 {
  padding-left: 40px;
}

.g-pr-40 {
  padding-right: 40px;
}

.g-ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.g-pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.g-rounded-p40 {
  border-radius: 40px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 40px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 40px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 40px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 40px;
}

.g-w-42 {
  width: 42px !important;
}

.g-min-w-42 {
  min-width: 42px !important;
}

.g-max-w-42 {
  max-width: 42px !important;
}

.g-h-42 {
  height: 42px;
}

.g-min-h-42 {
  min-height: 42px;
}

.g-ma-42 {
  margin: 42px;
}

.g-mh-42 {
  margin-left: 42px;
  margin-right: 42px;
}

.g-mv-42 {
  margin-top: 42px;
  margin-bottom: 42px;
}

.g-mt-42 {
  margin-top: 42px;
}

.g-mt-n42 {
  margin-top: -42px;
}

.g-mb-42 {
  margin-bottom: 42px;
}

.g-mb-n42 {
  margin-bottom: -42px;
}

.g-ml-42 {
  margin-left: 42px;
}

.g-ml-n42 {
  margin-left: -42px;
}

.g-mr-42 {
  margin-right: 42px;
}

.g-mr-n42 {
  margin-right: -42px;
}

.g-pa-42 {
  padding: 42px;
}

.g-pt-42 {
  padding-top: 42px;
}

.g-pb-42 {
  padding-bottom: 42px;
}

.g-pl-42 {
  padding-left: 42px;
}

.g-pr-42 {
  padding-right: 42px;
}

.g-ph-42 {
  padding-left: 42px;
  padding-right: 42px;
}

.g-pv-42 {
  padding-top: 42px;
  padding-bottom: 42px;
}

.g-rounded-p42 {
  border-radius: 42px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 42px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 42px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 42px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 42px;
}

.g-w-44 {
  width: 44px !important;
}

.g-min-w-44 {
  min-width: 44px !important;
}

.g-max-w-44 {
  max-width: 44px !important;
}

.g-h-44 {
  height: 44px;
}

.g-min-h-44 {
  min-height: 44px;
}

.g-ma-44 {
  margin: 44px;
}

.g-mh-44 {
  margin-left: 44px;
  margin-right: 44px;
}

.g-mv-44 {
  margin-top: 44px;
  margin-bottom: 44px;
}

.g-mt-44 {
  margin-top: 44px;
}

.g-mt-n44 {
  margin-top: -44px;
}

.g-mb-44 {
  margin-bottom: 44px;
}

.g-mb-n44 {
  margin-bottom: -44px;
}

.g-ml-44 {
  margin-left: 44px;
}

.g-ml-n44 {
  margin-left: -44px;
}

.g-mr-44 {
  margin-right: 44px;
}

.g-mr-n44 {
  margin-right: -44px;
}

.g-pa-44 {
  padding: 44px;
}

.g-pt-44 {
  padding-top: 44px;
}

.g-pb-44 {
  padding-bottom: 44px;
}

.g-pl-44 {
  padding-left: 44px;
}

.g-pr-44 {
  padding-right: 44px;
}

.g-ph-44 {
  padding-left: 44px;
  padding-right: 44px;
}

.g-pv-44 {
  padding-top: 44px;
  padding-bottom: 44px;
}

.g-rounded-p44 {
  border-radius: 44px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 44px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 44px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 44px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 44px;
}

.g-w-50 {
  width: 50px !important;
}

.g-min-w-50 {
  min-width: 50px !important;
}

.g-max-w-50 {
  max-width: 50px !important;
}

.g-h-50 {
  height: 50px;
}

.g-min-h-50 {
  min-height: 50px;
}

.g-ma-50 {
  margin: 50px;
}

.g-mh-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.g-mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.g-mt-50 {
  margin-top: 50px;
}

.g-mt-n50 {
  margin-top: -50px;
}

.g-mb-50 {
  margin-bottom: 50px;
}

.g-mb-n50 {
  margin-bottom: -50px;
}

.g-ml-50 {
  margin-left: 50px;
}

.g-ml-n50 {
  margin-left: -50px;
}

.g-mr-50 {
  margin-right: 50px;
}

.g-mr-n50 {
  margin-right: -50px;
}

.g-pa-50 {
  padding: 50px;
}

.g-pt-50 {
  padding-top: 50px;
}

.g-pb-50 {
  padding-bottom: 50px;
}

.g-pl-50 {
  padding-left: 50px;
}

.g-pr-50 {
  padding-right: 50px;
}

.g-ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.g-pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.g-rounded-p50 {
  border-radius: 50px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 50px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 50px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 50px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 50px;
}

.g-w-54 {
  width: 54px !important;
}

.g-min-w-54 {
  min-width: 54px !important;
}

.g-max-w-54 {
  max-width: 54px !important;
}

.g-h-54 {
  height: 54px;
}

.g-min-h-54 {
  min-height: 54px;
}

.g-ma-54 {
  margin: 54px;
}

.g-mh-54 {
  margin-left: 54px;
  margin-right: 54px;
}

.g-mv-54 {
  margin-top: 54px;
  margin-bottom: 54px;
}

.g-mt-54 {
  margin-top: 54px;
}

.g-mt-n54 {
  margin-top: -54px;
}

.g-mb-54 {
  margin-bottom: 54px;
}

.g-mb-n54 {
  margin-bottom: -54px;
}

.g-ml-54 {
  margin-left: 54px;
}

.g-ml-n54 {
  margin-left: -54px;
}

.g-mr-54 {
  margin-right: 54px;
}

.g-mr-n54 {
  margin-right: -54px;
}

.g-pa-54 {
  padding: 54px;
}

.g-pt-54 {
  padding-top: 54px;
}

.g-pb-54 {
  padding-bottom: 54px;
}

.g-pl-54 {
  padding-left: 54px;
}

.g-pr-54 {
  padding-right: 54px;
}

.g-ph-54 {
  padding-left: 54px;
  padding-right: 54px;
}

.g-pv-54 {
  padding-top: 54px;
  padding-bottom: 54px;
}

.g-rounded-p54 {
  border-radius: 54px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 54px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 54px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 54px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 54px;
}

.g-w-60 {
  width: 60px !important;
}

.g-min-w-60 {
  min-width: 60px !important;
}

.g-max-w-60 {
  max-width: 60px !important;
}

.g-h-60 {
  height: 60px;
}

.g-min-h-60 {
  min-height: 60px;
}

.g-ma-60 {
  margin: 60px;
}

.g-mh-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.g-mv-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.g-mt-60 {
  margin-top: 60px;
}

.g-mt-n60 {
  margin-top: -60px;
}

.g-mb-60 {
  margin-bottom: 60px;
}

.g-mb-n60 {
  margin-bottom: -60px;
}

.g-ml-60 {
  margin-left: 60px;
}

.g-ml-n60 {
  margin-left: -60px;
}

.g-mr-60 {
  margin-right: 60px;
}

.g-mr-n60 {
  margin-right: -60px;
}

.g-pa-60 {
  padding: 60px;
}

.g-pt-60 {
  padding-top: 60px;
}

.g-pb-60 {
  padding-bottom: 60px;
}

.g-pl-60 {
  padding-left: 60px;
}

.g-pr-60 {
  padding-right: 60px;
}

.g-ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.g-pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.g-rounded-p60 {
  border-radius: 60px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 60px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 60px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 60px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 60px;
}

.g-w-65 {
  width: 65px !important;
}

.g-min-w-65 {
  min-width: 65px !important;
}

.g-max-w-65 {
  max-width: 65px !important;
}

.g-h-65 {
  height: 65px;
}

.g-min-h-65 {
  min-height: 65px;
}

.g-ma-65 {
  margin: 65px;
}

.g-mh-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.g-mv-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.g-mt-65 {
  margin-top: 65px;
}

.g-mt-n65 {
  margin-top: -65px;
}

.g-mb-65 {
  margin-bottom: 65px;
}

.g-mb-n65 {
  margin-bottom: -65px;
}

.g-ml-65 {
  margin-left: 65px;
}

.g-ml-n65 {
  margin-left: -65px;
}

.g-mr-65 {
  margin-right: 65px;
}

.g-mr-n65 {
  margin-right: -65px;
}

.g-pa-65 {
  padding: 65px;
}

.g-pt-65 {
  padding-top: 65px;
}

.g-pb-65 {
  padding-bottom: 65px;
}

.g-pl-65 {
  padding-left: 65px;
}

.g-pr-65 {
  padding-right: 65px;
}

.g-ph-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.g-pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.g-rounded-p65 {
  border-radius: 65px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 65px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 65px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 65px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 65px;
}

.g-w-67 {
  width: 67px !important;
}

.g-min-w-67 {
  min-width: 67px !important;
}

.g-max-w-67 {
  max-width: 67px !important;
}

.g-h-67 {
  height: 67px;
}

.g-min-h-67 {
  min-height: 67px;
}

.g-ma-67 {
  margin: 67px;
}

.g-mh-67 {
  margin-left: 67px;
  margin-right: 67px;
}

.g-mv-67 {
  margin-top: 67px;
  margin-bottom: 67px;
}

.g-mt-67 {
  margin-top: 67px;
}

.g-mt-n67 {
  margin-top: -67px;
}

.g-mb-67 {
  margin-bottom: 67px;
}

.g-mb-n67 {
  margin-bottom: -67px;
}

.g-ml-67 {
  margin-left: 67px;
}

.g-ml-n67 {
  margin-left: -67px;
}

.g-mr-67 {
  margin-right: 67px;
}

.g-mr-n67 {
  margin-right: -67px;
}

.g-pa-67 {
  padding: 67px;
}

.g-pt-67 {
  padding-top: 67px;
}

.g-pb-67 {
  padding-bottom: 67px;
}

.g-pl-67 {
  padding-left: 67px;
}

.g-pr-67 {
  padding-right: 67px;
}

.g-ph-67 {
  padding-left: 67px;
  padding-right: 67px;
}

.g-pv-67 {
  padding-top: 67px;
  padding-bottom: 67px;
}

.g-rounded-p67 {
  border-radius: 67px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 67px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 67px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 67px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 67px;
}

.g-w-70 {
  width: 70px !important;
}

.g-min-w-70 {
  min-width: 70px !important;
}

.g-max-w-70 {
  max-width: 70px !important;
}

.g-h-70 {
  height: 70px;
}

.g-min-h-70 {
  min-height: 70px;
}

.g-ma-70 {
  margin: 70px;
}

.g-mh-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.g-mv-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.g-mt-70 {
  margin-top: 70px;
}

.g-mt-n70 {
  margin-top: -70px;
}

.g-mb-70 {
  margin-bottom: 70px;
}

.g-mb-n70 {
  margin-bottom: -70px;
}

.g-ml-70 {
  margin-left: 70px;
}

.g-ml-n70 {
  margin-left: -70px;
}

.g-mr-70 {
  margin-right: 70px;
}

.g-mr-n70 {
  margin-right: -70px;
}

.g-pa-70 {
  padding: 70px;
}

.g-pt-70 {
  padding-top: 70px;
}

.g-pb-70 {
  padding-bottom: 70px;
}

.g-pl-70 {
  padding-left: 70px;
}

.g-pr-70 {
  padding-right: 70px;
}

.g-ph-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.g-pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.g-rounded-p70 {
  border-radius: 70px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 70px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 70px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 70px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 70px;
}

.g-w-75 {
  width: 75px !important;
}

.g-min-w-75 {
  min-width: 75px !important;
}

.g-max-w-75 {
  max-width: 75px !important;
}

.g-h-75 {
  height: 75px;
}

.g-min-h-75 {
  min-height: 75px;
}

.g-ma-75 {
  margin: 75px;
}

.g-mh-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.g-mv-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.g-mt-75 {
  margin-top: 75px;
}

.g-mt-n75 {
  margin-top: -75px;
}

.g-mb-75 {
  margin-bottom: 75px;
}

.g-mb-n75 {
  margin-bottom: -75px;
}

.g-ml-75 {
  margin-left: 75px;
}

.g-ml-n75 {
  margin-left: -75px;
}

.g-mr-75 {
  margin-right: 75px;
}

.g-mr-n75 {
  margin-right: -75px;
}

.g-pa-75 {
  padding: 75px;
}

.g-pt-75 {
  padding-top: 75px;
}

.g-pb-75 {
  padding-bottom: 75px;
}

.g-pl-75 {
  padding-left: 75px;
}

.g-pr-75 {
  padding-right: 75px;
}

.g-ph-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.g-pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.g-rounded-p75 {
  border-radius: 75px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 75px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 75px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 75px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 75px;
}

.g-w-79 {
  width: 79px !important;
}

.g-min-w-79 {
  min-width: 79px !important;
}

.g-max-w-79 {
  max-width: 79px !important;
}

.g-h-79 {
  height: 79px;
}

.g-min-h-79 {
  min-height: 79px;
}

.g-ma-79 {
  margin: 79px;
}

.g-mh-79 {
  margin-left: 79px;
  margin-right: 79px;
}

.g-mv-79 {
  margin-top: 79px;
  margin-bottom: 79px;
}

.g-mt-79 {
  margin-top: 79px;
}

.g-mt-n79 {
  margin-top: -79px;
}

.g-mb-79 {
  margin-bottom: 79px;
}

.g-mb-n79 {
  margin-bottom: -79px;
}

.g-ml-79 {
  margin-left: 79px;
}

.g-ml-n79 {
  margin-left: -79px;
}

.g-mr-79 {
  margin-right: 79px;
}

.g-mr-n79 {
  margin-right: -79px;
}

.g-pa-79 {
  padding: 79px;
}

.g-pt-79 {
  padding-top: 79px;
}

.g-pb-79 {
  padding-bottom: 79px;
}

.g-pl-79 {
  padding-left: 79px;
}

.g-pr-79 {
  padding-right: 79px;
}

.g-ph-79 {
  padding-left: 79px;
  padding-right: 79px;
}

.g-pv-79 {
  padding-top: 79px;
  padding-bottom: 79px;
}

.g-rounded-p79 {
  border-radius: 79px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 79px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 79px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 79px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 79px;
}

.g-w-80 {
  width: 80px !important;
}

.g-min-w-80 {
  min-width: 80px !important;
}

.g-max-w-80 {
  max-width: 80px !important;
}

.g-h-80 {
  height: 80px;
}

.g-min-h-80 {
  min-height: 80px;
}

.g-ma-80 {
  margin: 80px;
}

.g-mh-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.g-mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.g-mt-80 {
  margin-top: 80px;
}

.g-mt-n80 {
  margin-top: -80px;
}

.g-mb-80 {
  margin-bottom: 80px;
}

.g-mb-n80 {
  margin-bottom: -80px;
}

.g-ml-80 {
  margin-left: 80px;
}

.g-ml-n80 {
  margin-left: -80px;
}

.g-mr-80 {
  margin-right: 80px;
}

.g-mr-n80 {
  margin-right: -80px;
}

.g-pa-80 {
  padding: 80px;
}

.g-pt-80 {
  padding-top: 80px;
}

.g-pb-80 {
  padding-bottom: 80px;
}

.g-pl-80 {
  padding-left: 80px;
}

.g-pr-80 {
  padding-right: 80px;
}

.g-ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.g-pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.g-rounded-p80 {
  border-radius: 80px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 80px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 80px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 80px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 80px;
}

.g-w-82 {
  width: 82px !important;
}

.g-min-w-82 {
  min-width: 82px !important;
}

.g-max-w-82 {
  max-width: 82px !important;
}

.g-h-82 {
  height: 82px;
}

.g-min-h-82 {
  min-height: 82px;
}

.g-ma-82 {
  margin: 82px;
}

.g-mh-82 {
  margin-left: 82px;
  margin-right: 82px;
}

.g-mv-82 {
  margin-top: 82px;
  margin-bottom: 82px;
}

.g-mt-82 {
  margin-top: 82px;
}

.g-mt-n82 {
  margin-top: -82px;
}

.g-mb-82 {
  margin-bottom: 82px;
}

.g-mb-n82 {
  margin-bottom: -82px;
}

.g-ml-82 {
  margin-left: 82px;
}

.g-ml-n82 {
  margin-left: -82px;
}

.g-mr-82 {
  margin-right: 82px;
}

.g-mr-n82 {
  margin-right: -82px;
}

.g-pa-82 {
  padding: 82px;
}

.g-pt-82 {
  padding-top: 82px;
}

.g-pb-82 {
  padding-bottom: 82px;
}

.g-pl-82 {
  padding-left: 82px;
}

.g-pr-82 {
  padding-right: 82px;
}

.g-ph-82 {
  padding-left: 82px;
  padding-right: 82px;
}

.g-pv-82 {
  padding-top: 82px;
  padding-bottom: 82px;
}

.g-rounded-p82 {
  border-radius: 82px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 82px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 82px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 82px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 82px;
}

.g-w-87 {
  width: 87px !important;
}

.g-min-w-87 {
  min-width: 87px !important;
}

.g-max-w-87 {
  max-width: 87px !important;
}

.g-h-87 {
  height: 87px;
}

.g-min-h-87 {
  min-height: 87px;
}

.g-ma-87 {
  margin: 87px;
}

.g-mh-87 {
  margin-left: 87px;
  margin-right: 87px;
}

.g-mv-87 {
  margin-top: 87px;
  margin-bottom: 87px;
}

.g-mt-87 {
  margin-top: 87px;
}

.g-mt-n87 {
  margin-top: -87px;
}

.g-mb-87 {
  margin-bottom: 87px;
}

.g-mb-n87 {
  margin-bottom: -87px;
}

.g-ml-87 {
  margin-left: 87px;
}

.g-ml-n87 {
  margin-left: -87px;
}

.g-mr-87 {
  margin-right: 87px;
}

.g-mr-n87 {
  margin-right: -87px;
}

.g-pa-87 {
  padding: 87px;
}

.g-pt-87 {
  padding-top: 87px;
}

.g-pb-87 {
  padding-bottom: 87px;
}

.g-pl-87 {
  padding-left: 87px;
}

.g-pr-87 {
  padding-right: 87px;
}

.g-ph-87 {
  padding-left: 87px;
  padding-right: 87px;
}

.g-pv-87 {
  padding-top: 87px;
  padding-bottom: 87px;
}

.g-rounded-p87 {
  border-radius: 87px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 87px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 87px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 87px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 87px;
}

.g-w-90 {
  width: 90px !important;
}

.g-min-w-90 {
  min-width: 90px !important;
}

.g-max-w-90 {
  max-width: 90px !important;
}

.g-h-90 {
  height: 90px;
}

.g-min-h-90 {
  min-height: 90px;
}

.g-ma-90 {
  margin: 90px;
}

.g-mh-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.g-mv-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.g-mt-90 {
  margin-top: 90px;
}

.g-mt-n90 {
  margin-top: -90px;
}

.g-mb-90 {
  margin-bottom: 90px;
}

.g-mb-n90 {
  margin-bottom: -90px;
}

.g-ml-90 {
  margin-left: 90px;
}

.g-ml-n90 {
  margin-left: -90px;
}

.g-mr-90 {
  margin-right: 90px;
}

.g-mr-n90 {
  margin-right: -90px;
}

.g-pa-90 {
  padding: 90px;
}

.g-pt-90 {
  padding-top: 90px;
}

.g-pb-90 {
  padding-bottom: 90px;
}

.g-pl-90 {
  padding-left: 90px;
}

.g-pr-90 {
  padding-right: 90px;
}

.g-ph-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.g-pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.g-rounded-p90 {
  border-radius: 90px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 90px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 90px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 90px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 90px;
}

.g-w-100 {
  width: 100px !important;
}

.g-min-w-100 {
  min-width: 100px !important;
}

.g-max-w-100 {
  max-width: 100px !important;
}

.g-h-100 {
  height: 100px;
}

.g-min-h-100 {
  min-height: 100px;
}

.g-ma-100 {
  margin: 100px;
}

.g-mh-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.g-mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.g-mt-100 {
  margin-top: 100px;
}

.g-mt-n100 {
  margin-top: -100px;
}

.g-mb-100 {
  margin-bottom: 100px;
}

.g-mb-n100 {
  margin-bottom: -100px;
}

.g-ml-100 {
  margin-left: 100px;
}

.g-ml-n100 {
  margin-left: -100px;
}

.g-mr-100 {
  margin-right: 100px;
}

.g-mr-n100 {
  margin-right: -100px;
}

.g-pa-100 {
  padding: 100px;
}

.g-pt-100 {
  padding-top: 100px;
}

.g-pb-100 {
  padding-bottom: 100px;
}

.g-pl-100 {
  padding-left: 100px;
}

.g-pr-100 {
  padding-right: 100px;
}

.g-ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.g-pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.g-rounded-p100 {
  border-radius: 100px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 100px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 100px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 100px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 100px;
}

.g-w-110 {
  width: 110px !important;
}

.g-min-w-110 {
  min-width: 110px !important;
}

.g-max-w-110 {
  max-width: 110px !important;
}

.g-h-110 {
  height: 110px;
}

.g-min-h-110 {
  min-height: 110px;
}

.g-ma-110 {
  margin: 110px;
}

.g-mh-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.g-mv-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.g-mt-110 {
  margin-top: 110px;
}

.g-mt-n110 {
  margin-top: -110px;
}

.g-mb-110 {
  margin-bottom: 110px;
}

.g-mb-n110 {
  margin-bottom: -110px;
}

.g-ml-110 {
  margin-left: 110px;
}

.g-ml-n110 {
  margin-left: -110px;
}

.g-mr-110 {
  margin-right: 110px;
}

.g-mr-n110 {
  margin-right: -110px;
}

.g-pa-110 {
  padding: 110px;
}

.g-pt-110 {
  padding-top: 110px;
}

.g-pb-110 {
  padding-bottom: 110px;
}

.g-pl-110 {
  padding-left: 110px;
}

.g-pr-110 {
  padding-right: 110px;
}

.g-ph-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.g-pv-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.g-rounded-p110 {
  border-radius: 110px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 110px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 110px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 110px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 110px;
}

.g-w-115 {
  width: 115px !important;
}

.g-min-w-115 {
  min-width: 115px !important;
}

.g-max-w-115 {
  max-width: 115px !important;
}

.g-h-115 {
  height: 115px;
}

.g-min-h-115 {
  min-height: 115px;
}

.g-ma-115 {
  margin: 115px;
}

.g-mh-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.g-mv-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.g-mt-115 {
  margin-top: 115px;
}

.g-mt-n115 {
  margin-top: -115px;
}

.g-mb-115 {
  margin-bottom: 115px;
}

.g-mb-n115 {
  margin-bottom: -115px;
}

.g-ml-115 {
  margin-left: 115px;
}

.g-ml-n115 {
  margin-left: -115px;
}

.g-mr-115 {
  margin-right: 115px;
}

.g-mr-n115 {
  margin-right: -115px;
}

.g-pa-115 {
  padding: 115px;
}

.g-pt-115 {
  padding-top: 115px;
}

.g-pb-115 {
  padding-bottom: 115px;
}

.g-pl-115 {
  padding-left: 115px;
}

.g-pr-115 {
  padding-right: 115px;
}

.g-ph-115 {
  padding-left: 115px;
  padding-right: 115px;
}

.g-pv-115 {
  padding-top: 115px;
  padding-bottom: 115px;
}

.g-rounded-p115 {
  border-radius: 115px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 115px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 115px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 115px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 115px;
}

.g-w-120 {
  width: 120px !important;
}

.g-min-w-120 {
  min-width: 120px !important;
}

.g-max-w-120 {
  max-width: 120px !important;
}

.g-h-120 {
  height: 120px;
}

.g-min-h-120 {
  min-height: 120px;
}

.g-ma-120 {
  margin: 120px;
}

.g-mh-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.g-mv-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.g-mt-120 {
  margin-top: 120px;
}

.g-mt-n120 {
  margin-top: -120px;
}

.g-mb-120 {
  margin-bottom: 120px;
}

.g-mb-n120 {
  margin-bottom: -120px;
}

.g-ml-120 {
  margin-left: 120px;
}

.g-ml-n120 {
  margin-left: -120px;
}

.g-mr-120 {
  margin-right: 120px;
}

.g-mr-n120 {
  margin-right: -120px;
}

.g-pa-120 {
  padding: 120px;
}

.g-pt-120 {
  padding-top: 120px;
}

.g-pb-120 {
  padding-bottom: 120px;
}

.g-pl-120 {
  padding-left: 120px;
}

.g-pr-120 {
  padding-right: 120px;
}

.g-ph-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.g-pv-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.g-rounded-p120 {
  border-radius: 120px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 120px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 120px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 120px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 120px;
}

.g-w-147 {
  width: 147px !important;
}

.g-min-w-147 {
  min-width: 147px !important;
}

.g-max-w-147 {
  max-width: 147px !important;
}

.g-h-147 {
  height: 147px;
}

.g-min-h-147 {
  min-height: 147px;
}

.g-ma-147 {
  margin: 147px;
}

.g-mh-147 {
  margin-left: 147px;
  margin-right: 147px;
}

.g-mv-147 {
  margin-top: 147px;
  margin-bottom: 147px;
}

.g-mt-147 {
  margin-top: 147px;
}

.g-mt-n147 {
  margin-top: -147px;
}

.g-mb-147 {
  margin-bottom: 147px;
}

.g-mb-n147 {
  margin-bottom: -147px;
}

.g-ml-147 {
  margin-left: 147px;
}

.g-ml-n147 {
  margin-left: -147px;
}

.g-mr-147 {
  margin-right: 147px;
}

.g-mr-n147 {
  margin-right: -147px;
}

.g-pa-147 {
  padding: 147px;
}

.g-pt-147 {
  padding-top: 147px;
}

.g-pb-147 {
  padding-bottom: 147px;
}

.g-pl-147 {
  padding-left: 147px;
}

.g-pr-147 {
  padding-right: 147px;
}

.g-ph-147 {
  padding-left: 147px;
  padding-right: 147px;
}

.g-pv-147 {
  padding-top: 147px;
  padding-bottom: 147px;
}

.g-rounded-p147 {
  border-radius: 147px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 147px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 147px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 147px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 147px;
}

.g-w-160 {
  width: 160px !important;
}

.g-min-w-160 {
  min-width: 160px !important;
}

.g-max-w-160 {
  max-width: 160px !important;
}

.g-h-160 {
  height: 160px;
}

.g-min-h-160 {
  min-height: 160px;
}

.g-ma-160 {
  margin: 160px;
}

.g-mh-160 {
  margin-left: 160px;
  margin-right: 160px;
}

.g-mv-160 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.g-mt-160 {
  margin-top: 160px;
}

.g-mt-n160 {
  margin-top: -160px;
}

.g-mb-160 {
  margin-bottom: 160px;
}

.g-mb-n160 {
  margin-bottom: -160px;
}

.g-ml-160 {
  margin-left: 160px;
}

.g-ml-n160 {
  margin-left: -160px;
}

.g-mr-160 {
  margin-right: 160px;
}

.g-mr-n160 {
  margin-right: -160px;
}

.g-pa-160 {
  padding: 160px;
}

.g-pt-160 {
  padding-top: 160px;
}

.g-pb-160 {
  padding-bottom: 160px;
}

.g-pl-160 {
  padding-left: 160px;
}

.g-pr-160 {
  padding-right: 160px;
}

.g-ph-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.g-pv-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.g-rounded-p160 {
  border-radius: 160px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 160px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 160px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 160px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 160px;
}

.g-w-200 {
  width: 200px !important;
}

.g-min-w-200 {
  min-width: 200px !important;
}

.g-max-w-200 {
  max-width: 200px !important;
}

.g-h-200 {
  height: 200px;
}

.g-min-h-200 {
  min-height: 200px;
}

.g-ma-200 {
  margin: 200px;
}

.g-mh-200 {
  margin-left: 200px;
  margin-right: 200px;
}

.g-mv-200 {
  margin-top: 200px;
  margin-bottom: 200px;
}

.g-mt-200 {
  margin-top: 200px;
}

.g-mt-n200 {
  margin-top: -200px;
}

.g-mb-200 {
  margin-bottom: 200px;
}

.g-mb-n200 {
  margin-bottom: -200px;
}

.g-ml-200 {
  margin-left: 200px;
}

.g-ml-n200 {
  margin-left: -200px;
}

.g-mr-200 {
  margin-right: 200px;
}

.g-mr-n200 {
  margin-right: -200px;
}

.g-pa-200 {
  padding: 200px;
}

.g-pt-200 {
  padding-top: 200px;
}

.g-pb-200 {
  padding-bottom: 200px;
}

.g-pl-200 {
  padding-left: 200px;
}

.g-pr-200 {
  padding-right: 200px;
}

.g-ph-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.g-pv-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.g-rounded-p200 {
  border-radius: 200px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 200px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 200px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 200px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 200px;
}

.g-w-267 {
  width: 267px !important;
}

.g-min-w-267 {
  min-width: 267px !important;
}

.g-max-w-267 {
  max-width: 267px !important;
}

.g-h-267 {
  height: 267px;
}

.g-min-h-267 {
  min-height: 267px;
}

.g-ma-267 {
  margin: 267px;
}

.g-mh-267 {
  margin-left: 267px;
  margin-right: 267px;
}

.g-mv-267 {
  margin-top: 267px;
  margin-bottom: 267px;
}

.g-mt-267 {
  margin-top: 267px;
}

.g-mt-n267 {
  margin-top: -267px;
}

.g-mb-267 {
  margin-bottom: 267px;
}

.g-mb-n267 {
  margin-bottom: -267px;
}

.g-ml-267 {
  margin-left: 267px;
}

.g-ml-n267 {
  margin-left: -267px;
}

.g-mr-267 {
  margin-right: 267px;
}

.g-mr-n267 {
  margin-right: -267px;
}

.g-pa-267 {
  padding: 267px;
}

.g-pt-267 {
  padding-top: 267px;
}

.g-pb-267 {
  padding-bottom: 267px;
}

.g-pl-267 {
  padding-left: 267px;
}

.g-pr-267 {
  padding-right: 267px;
}

.g-ph-267 {
  padding-left: 267px;
  padding-right: 267px;
}

.g-pv-267 {
  padding-top: 267px;
  padding-bottom: 267px;
}

.g-rounded-p267 {
  border-radius: 267px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 267px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 267px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 267px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 267px;
}

.g-w-300 {
  width: 300px !important;
}

.g-min-w-300 {
  min-width: 300px !important;
}

.g-max-w-300 {
  max-width: 300px !important;
}

.g-h-300 {
  height: 300px;
}

.g-min-h-300 {
  min-height: 300px;
}

.g-ma-300 {
  margin: 300px;
}

.g-mh-300 {
  margin-left: 300px;
  margin-right: 300px;
}

.g-mv-300 {
  margin-top: 300px;
  margin-bottom: 300px;
}

.g-mt-300 {
  margin-top: 300px;
}

.g-mt-n300 {
  margin-top: -300px;
}

.g-mb-300 {
  margin-bottom: 300px;
}

.g-mb-n300 {
  margin-bottom: -300px;
}

.g-ml-300 {
  margin-left: 300px;
}

.g-ml-n300 {
  margin-left: -300px;
}

.g-mr-300 {
  margin-right: 300px;
}

.g-mr-n300 {
  margin-right: -300px;
}

.g-pa-300 {
  padding: 300px;
}

.g-pt-300 {
  padding-top: 300px;
}

.g-pb-300 {
  padding-bottom: 300px;
}

.g-pl-300 {
  padding-left: 300px;
}

.g-pr-300 {
  padding-right: 300px;
}

.g-ph-300 {
  padding-left: 300px;
  padding-right: 300px;
}

.g-pv-300 {
  padding-top: 300px;
  padding-bottom: 300px;
}

.g-rounded-p300 {
  border-radius: 300px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 300px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 300px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 300px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 300px;
}

.g-w-360 {
  width: 360px !important;
}

.g-min-w-360 {
  min-width: 360px !important;
}

.g-max-w-360 {
  max-width: 360px !important;
}

.g-h-360 {
  height: 360px;
}

.g-min-h-360 {
  min-height: 360px;
}

.g-ma-360 {
  margin: 360px;
}

.g-mh-360 {
  margin-left: 360px;
  margin-right: 360px;
}

.g-mv-360 {
  margin-top: 360px;
  margin-bottom: 360px;
}

.g-mt-360 {
  margin-top: 360px;
}

.g-mt-n360 {
  margin-top: -360px;
}

.g-mb-360 {
  margin-bottom: 360px;
}

.g-mb-n360 {
  margin-bottom: -360px;
}

.g-ml-360 {
  margin-left: 360px;
}

.g-ml-n360 {
  margin-left: -360px;
}

.g-mr-360 {
  margin-right: 360px;
}

.g-mr-n360 {
  margin-right: -360px;
}

.g-pa-360 {
  padding: 360px;
}

.g-pt-360 {
  padding-top: 360px;
}

.g-pb-360 {
  padding-bottom: 360px;
}

.g-pl-360 {
  padding-left: 360px;
}

.g-pr-360 {
  padding-right: 360px;
}

.g-ph-360 {
  padding-left: 360px;
  padding-right: 360px;
}

.g-pv-360 {
  padding-top: 360px;
  padding-bottom: 360px;
}

.g-rounded-p360 {
  border-radius: 360px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 360px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 360px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 360px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 360px;
}

.g-w-373 {
  width: 373px !important;
}

.g-min-w-373 {
  min-width: 373px !important;
}

.g-max-w-373 {
  max-width: 373px !important;
}

.g-h-373 {
  height: 373px;
}

.g-min-h-373 {
  min-height: 373px;
}

.g-ma-373 {
  margin: 373px;
}

.g-mh-373 {
  margin-left: 373px;
  margin-right: 373px;
}

.g-mv-373 {
  margin-top: 373px;
  margin-bottom: 373px;
}

.g-mt-373 {
  margin-top: 373px;
}

.g-mt-n373 {
  margin-top: -373px;
}

.g-mb-373 {
  margin-bottom: 373px;
}

.g-mb-n373 {
  margin-bottom: -373px;
}

.g-ml-373 {
  margin-left: 373px;
}

.g-ml-n373 {
  margin-left: -373px;
}

.g-mr-373 {
  margin-right: 373px;
}

.g-mr-n373 {
  margin-right: -373px;
}

.g-pa-373 {
  padding: 373px;
}

.g-pt-373 {
  padding-top: 373px;
}

.g-pb-373 {
  padding-bottom: 373px;
}

.g-pl-373 {
  padding-left: 373px;
}

.g-pr-373 {
  padding-right: 373px;
}

.g-ph-373 {
  padding-left: 373px;
  padding-right: 373px;
}

.g-pv-373 {
  padding-top: 373px;
  padding-bottom: 373px;
}

.g-rounded-p373 {
  border-radius: 373px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 373px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 373px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 373px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 373px;
}

.g-w-400 {
  width: 400px !important;
}

.g-min-w-400 {
  min-width: 400px !important;
}

.g-max-w-400 {
  max-width: 400px !important;
}

.g-h-400 {
  height: 400px;
}

.g-min-h-400 {
  min-height: 400px;
}

.g-ma-400 {
  margin: 400px;
}

.g-mh-400 {
  margin-left: 400px;
  margin-right: 400px;
}

.g-mv-400 {
  margin-top: 400px;
  margin-bottom: 400px;
}

.g-mt-400 {
  margin-top: 400px;
}

.g-mt-n400 {
  margin-top: -400px;
}

.g-mb-400 {
  margin-bottom: 400px;
}

.g-mb-n400 {
  margin-bottom: -400px;
}

.g-ml-400 {
  margin-left: 400px;
}

.g-ml-n400 {
  margin-left: -400px;
}

.g-mr-400 {
  margin-right: 400px;
}

.g-mr-n400 {
  margin-right: -400px;
}

.g-pa-400 {
  padding: 400px;
}

.g-pt-400 {
  padding-top: 400px;
}

.g-pb-400 {
  padding-bottom: 400px;
}

.g-pl-400 {
  padding-left: 400px;
}

.g-pr-400 {
  padding-right: 400px;
}

.g-ph-400 {
  padding-left: 400px;
  padding-right: 400px;
}

.g-pv-400 {
  padding-top: 400px;
  padding-bottom: 400px;
}

.g-rounded-p400 {
  border-radius: 400px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 400px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 400px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 400px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 400px;
}

.g-w-420 {
  width: 420px !important;
}

.g-min-w-420 {
  min-width: 420px !important;
}

.g-max-w-420 {
  max-width: 420px !important;
}

.g-h-420 {
  height: 420px;
}

.g-min-h-420 {
  min-height: 420px;
}

.g-ma-420 {
  margin: 420px;
}

.g-mh-420 {
  margin-left: 420px;
  margin-right: 420px;
}

.g-mv-420 {
  margin-top: 420px;
  margin-bottom: 420px;
}

.g-mt-420 {
  margin-top: 420px;
}

.g-mt-n420 {
  margin-top: -420px;
}

.g-mb-420 {
  margin-bottom: 420px;
}

.g-mb-n420 {
  margin-bottom: -420px;
}

.g-ml-420 {
  margin-left: 420px;
}

.g-ml-n420 {
  margin-left: -420px;
}

.g-mr-420 {
  margin-right: 420px;
}

.g-mr-n420 {
  margin-right: -420px;
}

.g-pa-420 {
  padding: 420px;
}

.g-pt-420 {
  padding-top: 420px;
}

.g-pb-420 {
  padding-bottom: 420px;
}

.g-pl-420 {
  padding-left: 420px;
}

.g-pr-420 {
  padding-right: 420px;
}

.g-ph-420 {
  padding-left: 420px;
  padding-right: 420px;
}

.g-pv-420 {
  padding-top: 420px;
  padding-bottom: 420px;
}

.g-rounded-p420 {
  border-radius: 420px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 420px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 420px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 420px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 420px;
}

.g-w-485 {
  width: 485px !important;
}

.g-min-w-485 {
  min-width: 485px !important;
}

.g-max-w-485 {
  max-width: 485px !important;
}

.g-h-485 {
  height: 485px;
}

.g-min-h-485 {
  min-height: 485px;
}

.g-ma-485 {
  margin: 485px;
}

.g-mh-485 {
  margin-left: 485px;
  margin-right: 485px;
}

.g-mv-485 {
  margin-top: 485px;
  margin-bottom: 485px;
}

.g-mt-485 {
  margin-top: 485px;
}

.g-mt-n485 {
  margin-top: -485px;
}

.g-mb-485 {
  margin-bottom: 485px;
}

.g-mb-n485 {
  margin-bottom: -485px;
}

.g-ml-485 {
  margin-left: 485px;
}

.g-ml-n485 {
  margin-left: -485px;
}

.g-mr-485 {
  margin-right: 485px;
}

.g-mr-n485 {
  margin-right: -485px;
}

.g-pa-485 {
  padding: 485px;
}

.g-pt-485 {
  padding-top: 485px;
}

.g-pb-485 {
  padding-bottom: 485px;
}

.g-pl-485 {
  padding-left: 485px;
}

.g-pr-485 {
  padding-right: 485px;
}

.g-ph-485 {
  padding-left: 485px;
  padding-right: 485px;
}

.g-pv-485 {
  padding-top: 485px;
  padding-bottom: 485px;
}

.g-rounded-p485 {
  border-radius: 485px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 485px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 485px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 485px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 485px;
}

.g-w-500 {
  width: 500px !important;
}

.g-min-w-500 {
  min-width: 500px !important;
}

.g-max-w-500 {
  max-width: 500px !important;
}

.g-h-500 {
  height: 500px;
}

.g-min-h-500 {
  min-height: 500px;
}

.g-ma-500 {
  margin: 500px;
}

.g-mh-500 {
  margin-left: 500px;
  margin-right: 500px;
}

.g-mv-500 {
  margin-top: 500px;
  margin-bottom: 500px;
}

.g-mt-500 {
  margin-top: 500px;
}

.g-mt-n500 {
  margin-top: -500px;
}

.g-mb-500 {
  margin-bottom: 500px;
}

.g-mb-n500 {
  margin-bottom: -500px;
}

.g-ml-500 {
  margin-left: 500px;
}

.g-ml-n500 {
  margin-left: -500px;
}

.g-mr-500 {
  margin-right: 500px;
}

.g-mr-n500 {
  margin-right: -500px;
}

.g-pa-500 {
  padding: 500px;
}

.g-pt-500 {
  padding-top: 500px;
}

.g-pb-500 {
  padding-bottom: 500px;
}

.g-pl-500 {
  padding-left: 500px;
}

.g-pr-500 {
  padding-right: 500px;
}

.g-ph-500 {
  padding-left: 500px;
  padding-right: 500px;
}

.g-pv-500 {
  padding-top: 500px;
  padding-bottom: 500px;
}

.g-rounded-p500 {
  border-radius: 500px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 500px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 500px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 500px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 500px;
}

.g-w-580 {
  width: 580px !important;
}

.g-min-w-580 {
  min-width: 580px !important;
}

.g-max-w-580 {
  max-width: 580px !important;
}

.g-h-580 {
  height: 580px;
}

.g-min-h-580 {
  min-height: 580px;
}

.g-ma-580 {
  margin: 580px;
}

.g-mh-580 {
  margin-left: 580px;
  margin-right: 580px;
}

.g-mv-580 {
  margin-top: 580px;
  margin-bottom: 580px;
}

.g-mt-580 {
  margin-top: 580px;
}

.g-mt-n580 {
  margin-top: -580px;
}

.g-mb-580 {
  margin-bottom: 580px;
}

.g-mb-n580 {
  margin-bottom: -580px;
}

.g-ml-580 {
  margin-left: 580px;
}

.g-ml-n580 {
  margin-left: -580px;
}

.g-mr-580 {
  margin-right: 580px;
}

.g-mr-n580 {
  margin-right: -580px;
}

.g-pa-580 {
  padding: 580px;
}

.g-pt-580 {
  padding-top: 580px;
}

.g-pb-580 {
  padding-bottom: 580px;
}

.g-pl-580 {
  padding-left: 580px;
}

.g-pr-580 {
  padding-right: 580px;
}

.g-ph-580 {
  padding-left: 580px;
  padding-right: 580px;
}

.g-pv-580 {
  padding-top: 580px;
  padding-bottom: 580px;
}

.g-rounded-p580 {
  border-radius: 580px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 580px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 580px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 580px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 580px;
}

.g-w-600 {
  width: 600px !important;
}

.g-min-w-600 {
  min-width: 600px !important;
}

.g-max-w-600 {
  max-width: 600px !important;
}

.g-h-600 {
  height: 600px;
}

.g-min-h-600 {
  min-height: 600px;
}

.g-ma-600 {
  margin: 600px;
}

.g-mh-600 {
  margin-left: 600px;
  margin-right: 600px;
}

.g-mv-600 {
  margin-top: 600px;
  margin-bottom: 600px;
}

.g-mt-600 {
  margin-top: 600px;
}

.g-mt-n600 {
  margin-top: -600px;
}

.g-mb-600 {
  margin-bottom: 600px;
}

.g-mb-n600 {
  margin-bottom: -600px;
}

.g-ml-600 {
  margin-left: 600px;
}

.g-ml-n600 {
  margin-left: -600px;
}

.g-mr-600 {
  margin-right: 600px;
}

.g-mr-n600 {
  margin-right: -600px;
}

.g-pa-600 {
  padding: 600px;
}

.g-pt-600 {
  padding-top: 600px;
}

.g-pb-600 {
  padding-bottom: 600px;
}

.g-pl-600 {
  padding-left: 600px;
}

.g-pr-600 {
  padding-right: 600px;
}

.g-ph-600 {
  padding-left: 600px;
  padding-right: 600px;
}

.g-pv-600 {
  padding-top: 600px;
  padding-bottom: 600px;
}

.g-rounded-p600 {
  border-radius: 600px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 600px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 600px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 600px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 600px;
}

.g-w-720 {
  width: 720px !important;
}

.g-min-w-720 {
  min-width: 720px !important;
}

.g-max-w-720 {
  max-width: 720px !important;
}

.g-h-720 {
  height: 720px;
}

.g-min-h-720 {
  min-height: 720px;
}

.g-ma-720 {
  margin: 720px;
}

.g-mh-720 {
  margin-left: 720px;
  margin-right: 720px;
}

.g-mv-720 {
  margin-top: 720px;
  margin-bottom: 720px;
}

.g-mt-720 {
  margin-top: 720px;
}

.g-mt-n720 {
  margin-top: -720px;
}

.g-mb-720 {
  margin-bottom: 720px;
}

.g-mb-n720 {
  margin-bottom: -720px;
}

.g-ml-720 {
  margin-left: 720px;
}

.g-ml-n720 {
  margin-left: -720px;
}

.g-mr-720 {
  margin-right: 720px;
}

.g-mr-n720 {
  margin-right: -720px;
}

.g-pa-720 {
  padding: 720px;
}

.g-pt-720 {
  padding-top: 720px;
}

.g-pb-720 {
  padding-bottom: 720px;
}

.g-pl-720 {
  padding-left: 720px;
}

.g-pr-720 {
  padding-right: 720px;
}

.g-ph-720 {
  padding-left: 720px;
  padding-right: 720px;
}

.g-pv-720 {
  padding-top: 720px;
  padding-bottom: 720px;
}

.g-rounded-p720 {
  border-radius: 720px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 720px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 720px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 720px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 720px;
}

.g-w-800 {
  width: 800px !important;
}

.g-min-w-800 {
  min-width: 800px !important;
}

.g-max-w-800 {
  max-width: 800px !important;
}

.g-h-800 {
  height: 800px;
}

.g-min-h-800 {
  min-height: 800px;
}

.g-ma-800 {
  margin: 800px;
}

.g-mh-800 {
  margin-left: 800px;
  margin-right: 800px;
}

.g-mv-800 {
  margin-top: 800px;
  margin-bottom: 800px;
}

.g-mt-800 {
  margin-top: 800px;
}

.g-mt-n800 {
  margin-top: -800px;
}

.g-mb-800 {
  margin-bottom: 800px;
}

.g-mb-n800 {
  margin-bottom: -800px;
}

.g-ml-800 {
  margin-left: 800px;
}

.g-ml-n800 {
  margin-left: -800px;
}

.g-mr-800 {
  margin-right: 800px;
}

.g-mr-n800 {
  margin-right: -800px;
}

.g-pa-800 {
  padding: 800px;
}

.g-pt-800 {
  padding-top: 800px;
}

.g-pb-800 {
  padding-bottom: 800px;
}

.g-pl-800 {
  padding-left: 800px;
}

.g-pr-800 {
  padding-right: 800px;
}

.g-ph-800 {
  padding-left: 800px;
  padding-right: 800px;
}

.g-pv-800 {
  padding-top: 800px;
  padding-bottom: 800px;
}

.g-rounded-p800 {
  border-radius: 800px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 800px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 800px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 800px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 800px;
}

.g-w-854 {
  width: 854px !important;
}

.g-min-w-854 {
  min-width: 854px !important;
}

.g-max-w-854 {
  max-width: 854px !important;
}

.g-h-854 {
  height: 854px;
}

.g-min-h-854 {
  min-height: 854px;
}

.g-ma-854 {
  margin: 854px;
}

.g-mh-854 {
  margin-left: 854px;
  margin-right: 854px;
}

.g-mv-854 {
  margin-top: 854px;
  margin-bottom: 854px;
}

.g-mt-854 {
  margin-top: 854px;
}

.g-mt-n854 {
  margin-top: -854px;
}

.g-mb-854 {
  margin-bottom: 854px;
}

.g-mb-n854 {
  margin-bottom: -854px;
}

.g-ml-854 {
  margin-left: 854px;
}

.g-ml-n854 {
  margin-left: -854px;
}

.g-mr-854 {
  margin-right: 854px;
}

.g-mr-n854 {
  margin-right: -854px;
}

.g-pa-854 {
  padding: 854px;
}

.g-pt-854 {
  padding-top: 854px;
}

.g-pb-854 {
  padding-bottom: 854px;
}

.g-pl-854 {
  padding-left: 854px;
}

.g-pr-854 {
  padding-right: 854px;
}

.g-ph-854 {
  padding-left: 854px;
  padding-right: 854px;
}

.g-pv-854 {
  padding-top: 854px;
  padding-bottom: 854px;
}

.g-rounded-p854 {
  border-radius: 854px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 854px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 854px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 854px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 854px;
}

.g-w-900 {
  width: 900px !important;
}

.g-min-w-900 {
  min-width: 900px !important;
}

.g-max-w-900 {
  max-width: 900px !important;
}

.g-h-900 {
  height: 900px;
}

.g-min-h-900 {
  min-height: 900px;
}

.g-ma-900 {
  margin: 900px;
}

.g-mh-900 {
  margin-left: 900px;
  margin-right: 900px;
}

.g-mv-900 {
  margin-top: 900px;
  margin-bottom: 900px;
}

.g-mt-900 {
  margin-top: 900px;
}

.g-mt-n900 {
  margin-top: -900px;
}

.g-mb-900 {
  margin-bottom: 900px;
}

.g-mb-n900 {
  margin-bottom: -900px;
}

.g-ml-900 {
  margin-left: 900px;
}

.g-ml-n900 {
  margin-left: -900px;
}

.g-mr-900 {
  margin-right: 900px;
}

.g-mr-n900 {
  margin-right: -900px;
}

.g-pa-900 {
  padding: 900px;
}

.g-pt-900 {
  padding-top: 900px;
}

.g-pb-900 {
  padding-bottom: 900px;
}

.g-pl-900 {
  padding-left: 900px;
}

.g-pr-900 {
  padding-right: 900px;
}

.g-ph-900 {
  padding-left: 900px;
  padding-right: 900px;
}

.g-pv-900 {
  padding-top: 900px;
  padding-bottom: 900px;
}

.g-rounded-p900 {
  border-radius: 900px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 900px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 900px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 900px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 900px;
}

.g-w-1100 {
  width: 1100px !important;
}

.g-min-w-1100 {
  min-width: 1100px !important;
}

.g-max-w-1100 {
  max-width: 1100px !important;
}

.g-h-1100 {
  height: 1100px;
}

.g-min-h-1100 {
  min-height: 1100px;
}

.g-ma-1100 {
  margin: 1100px;
}

.g-mh-1100 {
  margin-left: 1100px;
  margin-right: 1100px;
}

.g-mv-1100 {
  margin-top: 1100px;
  margin-bottom: 1100px;
}

.g-mt-1100 {
  margin-top: 1100px;
}

.g-mt-n1100 {
  margin-top: -1100px;
}

.g-mb-1100 {
  margin-bottom: 1100px;
}

.g-mb-n1100 {
  margin-bottom: -1100px;
}

.g-ml-1100 {
  margin-left: 1100px;
}

.g-ml-n1100 {
  margin-left: -1100px;
}

.g-mr-1100 {
  margin-right: 1100px;
}

.g-mr-n1100 {
  margin-right: -1100px;
}

.g-pa-1100 {
  padding: 1100px;
}

.g-pt-1100 {
  padding-top: 1100px;
}

.g-pb-1100 {
  padding-bottom: 1100px;
}

.g-pl-1100 {
  padding-left: 1100px;
}

.g-pr-1100 {
  padding-right: 1100px;
}

.g-ph-1100 {
  padding-left: 1100px;
  padding-right: 1100px;
}

.g-pv-1100 {
  padding-top: 1100px;
  padding-bottom: 1100px;
}

.g-rounded-p1100 {
  border-radius: 1100px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 1100px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 1100px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 1100px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 1100px;
}

.g-w-1280 {
  width: 1280px !important;
}

.g-min-w-1280 {
  min-width: 1280px !important;
}

.g-max-w-1280 {
  max-width: 1280px !important;
}

.g-h-1280 {
  height: 1280px;
}

.g-min-h-1280 {
  min-height: 1280px;
}

.g-ma-1280 {
  margin: 1280px;
}

.g-mh-1280 {
  margin-left: 1280px;
  margin-right: 1280px;
}

.g-mv-1280 {
  margin-top: 1280px;
  margin-bottom: 1280px;
}

.g-mt-1280 {
  margin-top: 1280px;
}

.g-mt-n1280 {
  margin-top: -1280px;
}

.g-mb-1280 {
  margin-bottom: 1280px;
}

.g-mb-n1280 {
  margin-bottom: -1280px;
}

.g-ml-1280 {
  margin-left: 1280px;
}

.g-ml-n1280 {
  margin-left: -1280px;
}

.g-mr-1280 {
  margin-right: 1280px;
}

.g-mr-n1280 {
  margin-right: -1280px;
}

.g-pa-1280 {
  padding: 1280px;
}

.g-pt-1280 {
  padding-top: 1280px;
}

.g-pb-1280 {
  padding-bottom: 1280px;
}

.g-pl-1280 {
  padding-left: 1280px;
}

.g-pr-1280 {
  padding-right: 1280px;
}

.g-ph-1280 {
  padding-left: 1280px;
  padding-right: 1280px;
}

.g-pv-1280 {
  padding-top: 1280px;
  padding-bottom: 1280px;
}

.g-rounded-p1280 {
  border-radius: 1280px;
}

.g-rounded-tl-pkey {
  border-top-left-radius: 1280px;
}

.g-rounded-tr-pkey {
  border-top-right-radius: 1280px;
}

.g-rounded-bl-pkey {
  border-bottom-left-radius: 1280px;
}

.g-rounded-br-pkey {
  border-bottom-right-radius: 1280px;
}

.g-top-0 {
  top: 0px;
}

.g-bottom-0 {
  bottom: 0px;
}

.g-right-0 {
  right: 0px;
}

.g-left-0 {
  left: 0px;
}

.g-top-1 {
  top: 1px;
}

.g-bottom-1 {
  bottom: 1px;
}

.g-right-1 {
  right: 1px;
}

.g-left-1 {
  left: 1px;
}

.g-top-2 {
  top: 2px;
}

.g-bottom-2 {
  bottom: 2px;
}

.g-right-2 {
  right: 2px;
}

.g-left-2 {
  left: 2px;
}

.g-top-3 {
  top: 3px;
}

.g-bottom-3 {
  bottom: 3px;
}

.g-right-3 {
  right: 3px;
}

.g-left-3 {
  left: 3px;
}

.g-top-4 {
  top: 4px;
}

.g-bottom-4 {
  bottom: 4px;
}

.g-right-4 {
  right: 4px;
}

.g-left-4 {
  left: 4px;
}

.g-top-5 {
  top: 5px;
}

.g-bottom-5 {
  bottom: 5px;
}

.g-right-5 {
  right: 5px;
}

.g-left-5 {
  left: 5px;
}

.g-top-6 {
  top: 6px;
}

.g-bottom-6 {
  bottom: 6px;
}

.g-right-6 {
  right: 6px;
}

.g-left-6 {
  left: 6px;
}

.g-top-7 {
  top: 7px;
}

.g-bottom-7 {
  bottom: 7px;
}

.g-right-7 {
  right: 7px;
}

.g-left-7 {
  left: 7px;
}

.g-top-8 {
  top: 8px;
}

.g-bottom-8 {
  bottom: 8px;
}

.g-right-8 {
  right: 8px;
}

.g-left-8 {
  left: 8px;
}

.g-top-9 {
  top: 9px;
}

.g-bottom-9 {
  bottom: 9px;
}

.g-right-9 {
  right: 9px;
}

.g-left-9 {
  left: 9px;
}

.g-top-10 {
  top: 10px;
}

.g-bottom-10 {
  bottom: 10px;
}

.g-right-10 {
  right: 10px;
}

.g-left-10 {
  left: 10px;
}

.g-top-11 {
  top: 11px;
}

.g-bottom-11 {
  bottom: 11px;
}

.g-right-11 {
  right: 11px;
}

.g-left-11 {
  left: 11px;
}

.g-top-12 {
  top: 12px;
}

.g-bottom-12 {
  bottom: 12px;
}

.g-right-12 {
  right: 12px;
}

.g-left-12 {
  left: 12px;
}

.g-top-13 {
  top: 13px;
}

.g-bottom-13 {
  bottom: 13px;
}

.g-right-13 {
  right: 13px;
}

.g-left-13 {
  left: 13px;
}

.g-top-14 {
  top: 14px;
}

.g-bottom-14 {
  bottom: 14px;
}

.g-right-14 {
  right: 14px;
}

.g-left-14 {
  left: 14px;
}

.g-top-15 {
  top: 15px;
}

.g-bottom-15 {
  bottom: 15px;
}

.g-right-15 {
  right: 15px;
}

.g-left-15 {
  left: 15px;
}

.g-top-16 {
  top: 16px;
}

.g-bottom-16 {
  bottom: 16px;
}

.g-right-16 {
  right: 16px;
}

.g-left-16 {
  left: 16px;
}

.g-top-17 {
  top: 17px;
}

.g-bottom-17 {
  bottom: 17px;
}

.g-right-17 {
  right: 17px;
}

.g-left-17 {
  left: 17px;
}

.g-top-18 {
  top: 18px;
}

.g-bottom-18 {
  bottom: 18px;
}

.g-right-18 {
  right: 18px;
}

.g-left-18 {
  left: 18px;
}

.g-top-19 {
  top: 19px;
}

.g-bottom-19 {
  bottom: 19px;
}

.g-right-19 {
  right: 19px;
}

.g-left-19 {
  left: 19px;
}

.g-top-20 {
  top: 20px;
}

.g-bottom-20 {
  bottom: 20px;
}

.g-right-20 {
  right: 20px;
}

.g-left-20 {
  left: 20px;
}

.g-top-21 {
  top: 21px;
}

.g-bottom-21 {
  bottom: 21px;
}

.g-right-21 {
  right: 21px;
}

.g-left-21 {
  left: 21px;
}

.g-top-22 {
  top: 22px;
}

.g-bottom-22 {
  bottom: 22px;
}

.g-right-22 {
  right: 22px;
}

.g-left-22 {
  left: 22px;
}

.g-top-23 {
  top: 23px;
}

.g-bottom-23 {
  bottom: 23px;
}

.g-right-23 {
  right: 23px;
}

.g-left-23 {
  left: 23px;
}

.g-top-24 {
  top: 24px;
}

.g-bottom-24 {
  bottom: 24px;
}

.g-right-24 {
  right: 24px;
}

.g-left-24 {
  left: 24px;
}

.g-top-25 {
  top: 25px;
}

.g-bottom-25 {
  bottom: 25px;
}

.g-right-25 {
  right: 25px;
}

.g-left-25 {
  left: 25px;
}

.g-top-26 {
  top: 26px;
}

.g-bottom-26 {
  bottom: 26px;
}

.g-right-26 {
  right: 26px;
}

.g-left-26 {
  left: 26px;
}

.g-top-27 {
  top: 27px;
}

.g-bottom-27 {
  bottom: 27px;
}

.g-right-27 {
  right: 27px;
}

.g-left-27 {
  left: 27px;
}

.g-top-28 {
  top: 28px;
}

.g-bottom-28 {
  bottom: 28px;
}

.g-right-28 {
  right: 28px;
}

.g-left-28 {
  left: 28px;
}

.g-top-29 {
  top: 29px;
}

.g-bottom-29 {
  bottom: 29px;
}

.g-right-29 {
  right: 29px;
}

.g-left-29 {
  left: 29px;
}

.g-top-30 {
  top: 30px;
}

.g-bottom-30 {
  bottom: 30px;
}

.g-right-30 {
  right: 30px;
}

.g-left-30 {
  left: 30px;
}

.g-top-31 {
  top: 31px;
}

.g-bottom-31 {
  bottom: 31px;
}

.g-right-31 {
  right: 31px;
}

.g-left-31 {
  left: 31px;
}

.g-top-32 {
  top: 32px;
}

.g-bottom-32 {
  bottom: 32px;
}

.g-right-32 {
  right: 32px;
}

.g-left-32 {
  left: 32px;
}

.g-top-33 {
  top: 33px;
}

.g-bottom-33 {
  bottom: 33px;
}

.g-right-33 {
  right: 33px;
}

.g-left-33 {
  left: 33px;
}

.g-top-34 {
  top: 34px;
}

.g-bottom-34 {
  bottom: 34px;
}

.g-right-34 {
  right: 34px;
}

.g-left-34 {
  left: 34px;
}

.g-top-35 {
  top: 35px;
}

.g-bottom-35 {
  bottom: 35px;
}

.g-right-35 {
  right: 35px;
}

.g-left-35 {
  left: 35px;
}

.g-top-36 {
  top: 36px;
}

.g-bottom-36 {
  bottom: 36px;
}

.g-right-36 {
  right: 36px;
}

.g-left-36 {
  left: 36px;
}

.g-top-37 {
  top: 37px;
}

.g-bottom-37 {
  bottom: 37px;
}

.g-right-37 {
  right: 37px;
}

.g-left-37 {
  left: 37px;
}

.g-top-38 {
  top: 38px;
}

.g-bottom-38 {
  bottom: 38px;
}

.g-right-38 {
  right: 38px;
}

.g-left-38 {
  left: 38px;
}

.g-top-39 {
  top: 39px;
}

.g-bottom-39 {
  bottom: 39px;
}

.g-right-39 {
  right: 39px;
}

.g-left-39 {
  left: 39px;
}

.g-top-40 {
  top: 40px;
}

.g-bottom-40 {
  bottom: 40px;
}

.g-right-40 {
  right: 40px;
}

.g-left-40 {
  left: 40px;
}

.g-top-41 {
  top: 41px;
}

.g-bottom-41 {
  bottom: 41px;
}

.g-right-41 {
  right: 41px;
}

.g-left-41 {
  left: 41px;
}

.g-top-42 {
  top: 42px;
}

.g-bottom-42 {
  bottom: 42px;
}

.g-right-42 {
  right: 42px;
}

.g-left-42 {
  left: 42px;
}

.g-top-43 {
  top: 43px;
}

.g-bottom-43 {
  bottom: 43px;
}

.g-right-43 {
  right: 43px;
}

.g-left-43 {
  left: 43px;
}

.g-top-44 {
  top: 44px;
}

.g-bottom-44 {
  bottom: 44px;
}

.g-right-44 {
  right: 44px;
}

.g-left-44 {
  left: 44px;
}

.g-top-45 {
  top: 45px;
}

.g-bottom-45 {
  bottom: 45px;
}

.g-right-45 {
  right: 45px;
}

.g-left-45 {
  left: 45px;
}

.g-top-46 {
  top: 46px;
}

.g-bottom-46 {
  bottom: 46px;
}

.g-right-46 {
  right: 46px;
}

.g-left-46 {
  left: 46px;
}

.g-top-47 {
  top: 47px;
}

.g-bottom-47 {
  bottom: 47px;
}

.g-right-47 {
  right: 47px;
}

.g-left-47 {
  left: 47px;
}

.g-top-48 {
  top: 48px;
}

.g-bottom-48 {
  bottom: 48px;
}

.g-right-48 {
  right: 48px;
}

.g-left-48 {
  left: 48px;
}

.g-top-49 {
  top: 49px;
}

.g-bottom-49 {
  bottom: 49px;
}

.g-right-49 {
  right: 49px;
}

.g-left-49 {
  left: 49px;
}

.g-top-50 {
  top: 50px;
}

.g-bottom-50 {
  bottom: 50px;
}

.g-right-50 {
  right: 50px;
}

.g-left-50 {
  left: 50px;
}

.g-top-51 {
  top: 51px;
}

.g-bottom-51 {
  bottom: 51px;
}

.g-right-51 {
  right: 51px;
}

.g-left-51 {
  left: 51px;
}

.g-top-52 {
  top: 52px;
}

.g-bottom-52 {
  bottom: 52px;
}

.g-right-52 {
  right: 52px;
}

.g-left-52 {
  left: 52px;
}

.g-top-53 {
  top: 53px;
}

.g-bottom-53 {
  bottom: 53px;
}

.g-right-53 {
  right: 53px;
}

.g-left-53 {
  left: 53px;
}

.g-top-54 {
  top: 54px;
}

.g-bottom-54 {
  bottom: 54px;
}

.g-right-54 {
  right: 54px;
}

.g-left-54 {
  left: 54px;
}

.g-top-55 {
  top: 55px;
}

.g-bottom-55 {
  bottom: 55px;
}

.g-right-55 {
  right: 55px;
}

.g-left-55 {
  left: 55px;
}

.g-top-56 {
  top: 56px;
}

.g-bottom-56 {
  bottom: 56px;
}

.g-right-56 {
  right: 56px;
}

.g-left-56 {
  left: 56px;
}

.g-top-57 {
  top: 57px;
}

.g-bottom-57 {
  bottom: 57px;
}

.g-right-57 {
  right: 57px;
}

.g-left-57 {
  left: 57px;
}

.g-top-58 {
  top: 58px;
}

.g-bottom-58 {
  bottom: 58px;
}

.g-right-58 {
  right: 58px;
}

.g-left-58 {
  left: 58px;
}

.g-top-59 {
  top: 59px;
}

.g-bottom-59 {
  bottom: 59px;
}

.g-right-59 {
  right: 59px;
}

.g-left-59 {
  left: 59px;
}

.g-top-60 {
  top: 60px;
}

.g-bottom-60 {
  bottom: 60px;
}

.g-right-60 {
  right: 60px;
}

.g-left-60 {
  left: 60px;
}

.g-top-61 {
  top: 61px;
}

.g-bottom-61 {
  bottom: 61px;
}

.g-right-61 {
  right: 61px;
}

.g-left-61 {
  left: 61px;
}

.g-top-62 {
  top: 62px;
}

.g-bottom-62 {
  bottom: 62px;
}

.g-right-62 {
  right: 62px;
}

.g-left-62 {
  left: 62px;
}

.g-top-63 {
  top: 63px;
}

.g-bottom-63 {
  bottom: 63px;
}

.g-right-63 {
  right: 63px;
}

.g-left-63 {
  left: 63px;
}

.g-top-64 {
  top: 64px;
}

.g-bottom-64 {
  bottom: 64px;
}

.g-right-64 {
  right: 64px;
}

.g-left-64 {
  left: 64px;
}

.g-top-65 {
  top: 65px;
}

.g-bottom-65 {
  bottom: 65px;
}

.g-right-65 {
  right: 65px;
}

.g-left-65 {
  left: 65px;
}

.g-top-66 {
  top: 66px;
}

.g-bottom-66 {
  bottom: 66px;
}

.g-right-66 {
  right: 66px;
}

.g-left-66 {
  left: 66px;
}

.g-top-67 {
  top: 67px;
}

.g-bottom-67 {
  bottom: 67px;
}

.g-right-67 {
  right: 67px;
}

.g-left-67 {
  left: 67px;
}

.g-top-68 {
  top: 68px;
}

.g-bottom-68 {
  bottom: 68px;
}

.g-right-68 {
  right: 68px;
}

.g-left-68 {
  left: 68px;
}

.g-top-69 {
  top: 69px;
}

.g-bottom-69 {
  bottom: 69px;
}

.g-right-69 {
  right: 69px;
}

.g-left-69 {
  left: 69px;
}

.g-top-70 {
  top: 70px;
}

.g-bottom-70 {
  bottom: 70px;
}

.g-right-70 {
  right: 70px;
}

.g-left-70 {
  left: 70px;
}

.g-top-71 {
  top: 71px;
}

.g-bottom-71 {
  bottom: 71px;
}

.g-right-71 {
  right: 71px;
}

.g-left-71 {
  left: 71px;
}

.g-top-72 {
  top: 72px;
}

.g-bottom-72 {
  bottom: 72px;
}

.g-right-72 {
  right: 72px;
}

.g-left-72 {
  left: 72px;
}

.g-top-73 {
  top: 73px;
}

.g-bottom-73 {
  bottom: 73px;
}

.g-right-73 {
  right: 73px;
}

.g-left-73 {
  left: 73px;
}

.g-top-74 {
  top: 74px;
}

.g-bottom-74 {
  bottom: 74px;
}

.g-right-74 {
  right: 74px;
}

.g-left-74 {
  left: 74px;
}

.g-top-75 {
  top: 75px;
}

.g-bottom-75 {
  bottom: 75px;
}

.g-right-75 {
  right: 75px;
}

.g-left-75 {
  left: 75px;
}

.g-top-76 {
  top: 76px;
}

.g-bottom-76 {
  bottom: 76px;
}

.g-right-76 {
  right: 76px;
}

.g-left-76 {
  left: 76px;
}

.g-top-77 {
  top: 77px;
}

.g-bottom-77 {
  bottom: 77px;
}

.g-right-77 {
  right: 77px;
}

.g-left-77 {
  left: 77px;
}

.g-top-78 {
  top: 78px;
}

.g-bottom-78 {
  bottom: 78px;
}

.g-right-78 {
  right: 78px;
}

.g-left-78 {
  left: 78px;
}

.g-top-79 {
  top: 79px;
}

.g-bottom-79 {
  bottom: 79px;
}

.g-right-79 {
  right: 79px;
}

.g-left-79 {
  left: 79px;
}

.g-top-80 {
  top: 80px;
}

.g-bottom-80 {
  bottom: 80px;
}

.g-right-80 {
  right: 80px;
}

.g-left-80 {
  left: 80px;
}

.g-top-81 {
  top: 81px;
}

.g-bottom-81 {
  bottom: 81px;
}

.g-right-81 {
  right: 81px;
}

.g-left-81 {
  left: 81px;
}

.g-top-82 {
  top: 82px;
}

.g-bottom-82 {
  bottom: 82px;
}

.g-right-82 {
  right: 82px;
}

.g-left-82 {
  left: 82px;
}

.g-top-83 {
  top: 83px;
}

.g-bottom-83 {
  bottom: 83px;
}

.g-right-83 {
  right: 83px;
}

.g-left-83 {
  left: 83px;
}

.g-top-84 {
  top: 84px;
}

.g-bottom-84 {
  bottom: 84px;
}

.g-right-84 {
  right: 84px;
}

.g-left-84 {
  left: 84px;
}

.g-top-85 {
  top: 85px;
}

.g-bottom-85 {
  bottom: 85px;
}

.g-right-85 {
  right: 85px;
}

.g-left-85 {
  left: 85px;
}

.g-top-86 {
  top: 86px;
}

.g-bottom-86 {
  bottom: 86px;
}

.g-right-86 {
  right: 86px;
}

.g-left-86 {
  left: 86px;
}

.g-top-87 {
  top: 87px;
}

.g-bottom-87 {
  bottom: 87px;
}

.g-right-87 {
  right: 87px;
}

.g-left-87 {
  left: 87px;
}

.g-top-88 {
  top: 88px;
}

.g-bottom-88 {
  bottom: 88px;
}

.g-right-88 {
  right: 88px;
}

.g-left-88 {
  left: 88px;
}

.g-top-89 {
  top: 89px;
}

.g-bottom-89 {
  bottom: 89px;
}

.g-right-89 {
  right: 89px;
}

.g-left-89 {
  left: 89px;
}

.g-top-90 {
  top: 90px;
}

.g-bottom-90 {
  bottom: 90px;
}

.g-right-90 {
  right: 90px;
}

.g-left-90 {
  left: 90px;
}

.g-top-91 {
  top: 91px;
}

.g-bottom-91 {
  bottom: 91px;
}

.g-right-91 {
  right: 91px;
}

.g-left-91 {
  left: 91px;
}

.g-top-92 {
  top: 92px;
}

.g-bottom-92 {
  bottom: 92px;
}

.g-right-92 {
  right: 92px;
}

.g-left-92 {
  left: 92px;
}

.g-top-93 {
  top: 93px;
}

.g-bottom-93 {
  bottom: 93px;
}

.g-right-93 {
  right: 93px;
}

.g-left-93 {
  left: 93px;
}

.g-top-94 {
  top: 94px;
}

.g-bottom-94 {
  bottom: 94px;
}

.g-right-94 {
  right: 94px;
}

.g-left-94 {
  left: 94px;
}

.g-top-95 {
  top: 95px;
}

.g-bottom-95 {
  bottom: 95px;
}

.g-right-95 {
  right: 95px;
}

.g-left-95 {
  left: 95px;
}

.g-top-96 {
  top: 96px;
}

.g-bottom-96 {
  bottom: 96px;
}

.g-right-96 {
  right: 96px;
}

.g-left-96 {
  left: 96px;
}

.g-top-97 {
  top: 97px;
}

.g-bottom-97 {
  bottom: 97px;
}

.g-right-97 {
  right: 97px;
}

.g-left-97 {
  left: 97px;
}

.g-top-98 {
  top: 98px;
}

.g-bottom-98 {
  bottom: 98px;
}

.g-right-98 {
  right: 98px;
}

.g-left-98 {
  left: 98px;
}

.g-top-99 {
  top: 99px;
}

.g-bottom-99 {
  bottom: 99px;
}

.g-right-99 {
  right: 99px;
}

.g-left-99 {
  left: 99px;
}

.g-zindex-1 {
  z-index: 1;
}

.g-zindex-2 {
  z-index: 2;
}

.g-zindex-3 {
  z-index: 3;
}

.g-zindex-4 {
  z-index: 4;
}

.g-zindex-5 {
  z-index: 5;
}

.g-zindex-6 {
  z-index: 6;
}

.g-zindex-7 {
  z-index: 7;
}

.g-zindex-8 {
  z-index: 8;
}

.g-zindex-9 {
  z-index: 9;
}

.g-zindex-10 {
  z-index: 10;
}

.g-zindex-11 {
  z-index: 11;
}

.g-zindex-12 {
  z-index: 12;
}

.g-zindex-13 {
  z-index: 13;
}

.g-zindex-14 {
  z-index: 14;
}

.g-zindex-15 {
  z-index: 15;
}

.g-zindex-16 {
  z-index: 16;
}

.g-zindex-17 {
  z-index: 17;
}

.g-zindex-18 {
  z-index: 18;
}

.g-zindex-19 {
  z-index: 19;
}

.g-zindex-20 {
  z-index: 20;
}

.g-zindex-21 {
  z-index: 21;
}

.g-zindex-22 {
  z-index: 22;
}

.g-zindex-23 {
  z-index: 23;
}

.g-zindex-24 {
  z-index: 24;
}

.g-zindex-25 {
  z-index: 25;
}

.g-zindex-26 {
  z-index: 26;
}

.g-zindex-27 {
  z-index: 27;
}

.g-zindex-28 {
  z-index: 28;
}

.g-zindex-29 {
  z-index: 29;
}

.g-zindex-30 {
  z-index: 30;
}

.g-zindex-31 {
  z-index: 31;
}

.g-zindex-32 {
  z-index: 32;
}

.g-zindex-33 {
  z-index: 33;
}

.g-zindex-34 {
  z-index: 34;
}

.g-zindex-35 {
  z-index: 35;
}

.g-zindex-36 {
  z-index: 36;
}

.g-zindex-37 {
  z-index: 37;
}

.g-zindex-38 {
  z-index: 38;
}

.g-zindex-39 {
  z-index: 39;
}

.g-zindex-40 {
  z-index: 40;
}

.g-zindex-41 {
  z-index: 41;
}

.g-zindex-42 {
  z-index: 42;
}

.g-zindex-43 {
  z-index: 43;
}

.g-zindex-44 {
  z-index: 44;
}

.g-zindex-45 {
  z-index: 45;
}

.g-zindex-46 {
  z-index: 46;
}

.g-zindex-47 {
  z-index: 47;
}

.g-zindex-48 {
  z-index: 48;
}

.g-zindex-49 {
  z-index: 49;
}

.g-zindex-50 {
  z-index: 50;
}

.g-zindex-51 {
  z-index: 51;
}

.g-zindex-52 {
  z-index: 52;
}

.g-zindex-53 {
  z-index: 53;
}

.g-zindex-54 {
  z-index: 54;
}

.g-zindex-55 {
  z-index: 55;
}

.g-zindex-56 {
  z-index: 56;
}

.g-zindex-57 {
  z-index: 57;
}

.g-zindex-58 {
  z-index: 58;
}

.g-zindex-59 {
  z-index: 59;
}

.g-zindex-60 {
  z-index: 60;
}

.g-zindex-61 {
  z-index: 61;
}

.g-zindex-62 {
  z-index: 62;
}

.g-zindex-63 {
  z-index: 63;
}

.g-zindex-64 {
  z-index: 64;
}

.g-zindex-65 {
  z-index: 65;
}

.g-zindex-66 {
  z-index: 66;
}

.g-zindex-67 {
  z-index: 67;
}

.g-zindex-68 {
  z-index: 68;
}

.g-zindex-69 {
  z-index: 69;
}

.g-zindex-70 {
  z-index: 70;
}

.g-zindex-71 {
  z-index: 71;
}

.g-zindex-72 {
  z-index: 72;
}

.g-zindex-73 {
  z-index: 73;
}

.g-zindex-74 {
  z-index: 74;
}

.g-zindex-75 {
  z-index: 75;
}

.g-zindex-76 {
  z-index: 76;
}

.g-zindex-77 {
  z-index: 77;
}

.g-zindex-78 {
  z-index: 78;
}

.g-zindex-79 {
  z-index: 79;
}

.g-zindex-80 {
  z-index: 80;
}

.g-zindex-81 {
  z-index: 81;
}

.g-zindex-82 {
  z-index: 82;
}

.g-zindex-83 {
  z-index: 83;
}

.g-zindex-84 {
  z-index: 84;
}

.g-zindex-85 {
  z-index: 85;
}

.g-zindex-86 {
  z-index: 86;
}

.g-zindex-87 {
  z-index: 87;
}

.g-zindex-88 {
  z-index: 88;
}

.g-zindex-89 {
  z-index: 89;
}

.g-zindex-90 {
  z-index: 90;
}

.g-zindex-91 {
  z-index: 91;
}

.g-zindex-92 {
  z-index: 92;
}

.g-zindex-93 {
  z-index: 93;
}

.g-zindex-94 {
  z-index: 94;
}

.g-zindex-95 {
  z-index: 95;
}

.g-zindex-96 {
  z-index: 96;
}

.g-zindex-97 {
  z-index: 97;
}

.g-zindex-98 {
  z-index: 98;
}

.g-zindex-99 {
  z-index: 99;
}

.g-decorationNone {
  text-decoration: none !important;
}

.g-bg-white {
  background: #f8f8f8 !important;
}

.g-bg-dark {
  background: #2b2b2b !important;
}

.q-card {
  background: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.q-card--dark {
  background: #333333;
}

.q-dialog__inner > div {
  border-radius: 10px;
}

.q-drawer {
  background: #f8f8f8;
}

.q-drawer--dark {
  background: #2b2b2b;
}

.q-btn .q-icon {
  font-size: 1.4em;
}

.q-scrollarea__content {
  width: 100%;
}

.q-table__container {
  background: transparent;
}

.q-table--dark tbody tr:hover {
  background: #333333;
}

.q-table > thead > tr > th {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.87;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.g-column-fixed-left {
  position: sticky !important;
  left: 0;
  z-index: 1;
}

.q-table .g-column-fixed-left-background {
  background: #f8f8f8 !important;
}

.q-table--dark .g-column-fixed-left-background {
  background: #2b2b2b !important;
}

.g-column-fixed-right {
  position: sticky !important;
  right: 0;
  z-index: 1;
}

.q-table .g-column-fixed-right-background {
  background: #f8f8f8 !important;
}

.q-table--dark .g-column-fixed-right-background {
  background: #2b2b2b !important;
}

.q-table__top {
  padding: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #333333 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white;
}

.inputFormLogin {
  margin: 5px;
}

body.desktop .q-list .q-hoverable:hover > .q-focus-helper {
  background: currentColor;
  opacity: 0;
}

.q-field--filled.q-field--dark .q-field__control, .q-field--filled.q-field--dark .q-field__control:before {
  background: #333333;
}

.q-field--dark .q-dark.q-chip {
  background: #5d5d5d;
}

.q-item.q-router-link--active, .q-item--active {
  color: inherit;
}

.q-item.q-item-type.text-secondary {
  color: #000000 !important;
}

.q-item.q-item-type.q-item--dark.text-secondary {
  color: #ffffff !important;
}

.emoji-mart .emoji-mart-search input {
  margin-top: 6px;
  border: none !important;
  background: #f5f5f5;
}

.bg-dark > .emoji-mart .emoji-mart-search input {
  background: #454545;
  color: #ffffff;
}

.emoji-mart .emoji-mart-category-label {
  background: #ffffff;
}

.bg-dark > .emoji-mart .emoji-mart-category-label {
  background: #333333;
  color: #ffffff;
}

.emoji-mart .emoji-mart-category .emoji-mart-emoji:hover:before, .emoji-mart-emoji-selected:before {
  background: #f5f5f5;
}

.bg-dark .emoji-mart .emoji-mart-category .emoji-mart-emoji:hover:before, .emoji-mart-emoji-selected:before {
  background: #454545;
}

.emoji-mart .emoji-mart-anchor {
  color: #222427;
}

.bg-dark .emoji-mart .emoji-mart-anchor {
  color: #ffffff;
}

.emoji-mart .emoji-mart-anchor:hover, .emoji-mart-anchor-selected {
  color: #ff6600;
}

.emoji-mart > ::-webkit-scrollbar {
  width: 13px;
}

.emoji-mart > ::-webkit-scrollbar-track {
  border-radius: 10px;
}

.emoji-mart > ::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.emoji-mart:hover > ::-webkit-scrollbar-thumb {
  background: #dadada;
}

.emoji-mart > .emoji-mart-scroll {
  overflow: auto !important;
}

.emoji-mart > ::-webkit-scrollbar {
  width: 13px;
}

.emoji-mart > ::-webkit-scrollbar-track {
  border-radius: 10px;
}

.bg-dark .emoji-mart > ::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  border-radius: 10px;
  border: 3px solid #333333;
}

.bg-dark .emoji-mart:hover > ::-webkit-scrollbar-thumb {
  background: #424242;
}

.g-emoji-small {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-medium {
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-display .g-emoji-medium {
  width: 18px;
  height: 18px;
}

.q-select--multiple.q-field--dense.q-select--with-chips .q-field__native.row.items-center {
  flex-wrap: nowrap;
  overflow: hidden;
  min-height: 42px;
}

.q-field--standard .q-field__control:before {
  border-bottom-color: #dadada;
}

.q-field--dark .q-field__control:before {
  border-bottom-color: #454545;
}

.q-field__label {
  font-size: 14px;
}

.q-separator {
  background: #dadada;
}

.q-separator--dark {
  background: #454545;
}

.q-checkbox__inner {
  font-size: 32px;
}

::-webkit-scrollbar {
  background: #f8f8f8;
  width: 13px;
}

::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 10px;
  border: 3px solid #f8f8f8;
}

.g-scroll-dark ::-webkit-scrollbar {
  background: #2b2b2b;
}

.g-scroll-dark ::-webkit-scrollbar-thumb {
  background: #454545;
  border: 3px solid #2b2b2b;
}

.q-menu::-webkit-scrollbar {
  background: #ffffff;
  border-radius: 10px;
}

.q-menu::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 3px solid #ffffff;
}

.g-scroll-dark .q-menu::-webkit-scrollbar {
  background: #333333;
}

.g-scroll-dark .q-menu::-webkit-scrollbar-thumb {
  background: #454545;
  border: 3px solid #333333;
}

.q-tooltip--style {
  font-size: 12px;
}

.q-list > .q-item:hover {
  background: #dadada;
}

.q-list.q-list--dark > .q-item:hover {
  background: #333333;
}