html {
  color: #000000;
}

h1, h2, h3, h4, h5, h6, a, p {
  margin-bottom: 0 !important;
}

a,
a:hover {
  text-decoration: none !important;
}

.bg-black {
  transition: background 0.5s;
  will-change: background;
  background: #2b2b2b !important;
}

.bg-default {
  transition: background 0.5s;
  will-change: background;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.4);
  transition: background 0.5s;
  will-change: background;
}

.g-black-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.q-item-separator-component {
  background: #e6e6e6;
}

.q-list--dark .q-item-separator-component {
  background: rgba(255, 255, 255, 0.12);
}

.g-emoji-small {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-medium {
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}

.g-emoji-display .g-emoji-medium {
  width: 18px;
  height: 18px;
}

.emoji-mart {
  background: transparent !important;
  border: none !important;
}

.g-scrolled-bottom > .q-scrollarea__container {
  display: flex;
  flex-direction: column-reverse;
}

.g-scrolled-bottom > .q-scrollarea__thumb {
  flex-direction: column-reverse;
}

.g-scroll > .q-scrollarea__container {
  overflow: auto !important;
}

.g-scroll > .q-scrollarea-thumb {
  display: none;
}

.emoji-mart > .emoji-mart-scroll {
  overflow: auto !important;
}

.emoji-mart > .emoji-mart-bar {
  cursor: pointer;
}

.emoji-mart > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-emoji {
  cursor: pointer;
}

.emoji-mart > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-emoji > span {
  cursor: pointer;
}

.bg--dark > .emoji-mart > .emoji-mart-search > input {
  background: #414141;
}

.emoji-mart > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-category-label > span {
  border-radius: 10px;
  margin-top: 5px;
}

.bg--dark > .emoji-mart > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-category-label > span {
  background: #414141;
  color: #fff;
}

.bg--dark > .emoji-mart > .emoji-mart-scroll > .emoji-mart-category > .emoji-mart-emoji:before {
  background: #414141;
}

.g-message {
  position: relative;
  z-index: 1;
  min-width: 100px;
  min-height: 50px;
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  outline: none;
}

.g-annex > .q-fab > .q-btn-fab {
  width: 31.19px;
  height: 31.19px;
  background: #ffffff;
}

.g-annex-dark > .q-fab > .q-btn-fab {
  background: #555555;
}

.g-annex > .q-fab > .q-fab-actions > .q-btn-fab-mini {
  width: 31.19px;
  height: 31.19px;
  background: #ffffff;
}

.g-annex-dark > .q-fab > .q-fab-actions > .q-btn-fab-mini {
  width: 31.19px;
  height: 31.19px;
  background: #555555;
}

.g-annex > .q-fab > .q-fab-actions > .q-btn-fab-mini > .q-btn-inner > .q-icon {
  font-size: 18px;
}

.g-annex > .q-fab > .q-btn-fab > .q-btn-inner > .q-icon {
  font-size: 18px;
}

.g-annex > .q-fab > .q-btn-fab > .q-btn-inner > .fa-paperclip {
  padding-top: 6px;
}

.g-annex > .q-fab > .q-btn-fab > .q-btn-inner > .fa-spinner-third {
  padding-top: 6px;
}

.g-annex > .g-spin > .q-btn-fab > .q-btn-inner > .fa-spinner-third {
  -webkit-animation: q-spin 1s linear infinite;
  animation: q-spin 1s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.g-live-clients .g-live-client {
  display: none;
}

.g-live-clients:hover .g-live-client {
  display: block;
}

.text-secondary {
  color: #ff6600 !important;
}

.q-table--dark .q-table-bottom, .q-table--dark .q-table-top {
  border-top: none;
}

.v-step {
  z-index: 1000;
}

.q-btn-dropdown-arrow {
  display: none;
}

.left-drawer {
  color: #fafafa;
  font-size: 13px;
  padding-top: 10px;
}

.left-drawer-items {
  padding-top: 10px;
  padding-bottom: 10px;
  will-change: background-color;
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}

.left-drawer-items p {
  margin: 0;
}

.logo {
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 1 !important;
}

.default-layout {
  padding: 20px 40px 10px 40px;
}

.default-layout-background {
  padding: 30px 30px 10px 40px;
  background-color: #f8f8f8;
}

.default-title {
  font-size: 30px;
  font-weight: 200;
  margin: 0;
}

.default-subtitle {
  font-size: 22px;
  font-weight: 200;
}

.default-subtitle-lite {
  font-size: 22px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  line-height: 22px;
}

.q-input {
  font-size: 14px;
}

.q-input-target {
  font-size: 14px;
}

.left-drawer-items-un p {
  opacity: 0.2;
}

.left-drawer-items-un img {
  opacity: 0.2;
}

.qFabButton button {
  height: 40px;
}

.q-table-top {
  padding-left: 0;
}

.bg-default {
  background-color: #f8f8f8;
}

.v-step__content {
  font-size: 14px;
}

.bg-card {
  background: #333333;
}

.border--dark {
  border: solid 0.5px #454545;
}

.border-white {
  border: solid 0.5px #e6e6e6;
}

.border-dashed {
  border: 1px dashed #ff6600;
}

.q-tab.active {
  color: #ff6600;
}

.q-tabs-global-bar-container {
  color: #ff6600;
}

.q-tab-pane {
  padding: 12px 0 12px 0;
}

.g-header-text {
  font-size: 14px;
  font-weight: 600;
}

.g-border-bottom {
  border-bottom: 2px #ff6600 solid;
}

.g-border-bottom-transparent {
  border-bottom: 2px transparent solid;
}

.q-list-striped > .q-item:nth-child(even) {
  background-color: white;
}

.q-list--dark.q-list-striped > .q-item:nth-child(even) {
  background-color: #333333;
}

#sankey {
  height: 440px;
}

.max-w {
  max-width: 100%;
}

.max-h {
  max-height: 100%;
}

.modal-content {
  min-width: 550px;
}

table > tbody > tr > .text-right > .q-table-display {
  display: none;
}

table > tbody > tr > .text-right > .q-table-display-ellipsis {
  display: flex;
}

table > tbody > tr:hover > .text-right > .q-table-display {
  display: flex;
}

table > tbody > tr:hover > .text-right > .q-table-display-ellipsis {
  display: none;
}

.g-overflow-unset {
  overflow: unset;
}

.q-table tbody td {
  font-size: 14px;
}

.q-table th {
  font-size: 14px;
  font-weight: 600;
}

.g-card-title {
  font-size: 22px;
  padding: 0;
  font-weight: 300;
  margin-left: 5px;
}

.q-input > .q-icon {
  font-size: 21px;
  width: 22px;
  height: 18px;
}

.g-length {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.newChip .newChip-control {
  display: none;
}

.newChip:hover .newChip-control {
  display: block;
}

.chip-main .sub-chip-left {
  display: none;
}

.chip-main:hover .sub-chip-left {
  display: block;
}

.chip-main .sub-chip-right {
  display: none;
}

.chip-main:hover .sub-chip-right {
  display: block;
}

.sub-chip-left-style {
  width: 27px;
  height: 26px;
  position: absolute;
  z-index: 1;
  border-radius: 15px;
}

.sub-chip-right-style {
  width: 27px;
  height: 26px;
  background-color: #f5f5f5;
  position: absolute;
  float: right;
  z-index: 1;
  border-radius: 15px;
  margin-left: 15px;
}

.sub-chip-left-icon {
  padding-top: 2px;
  padding-left: 3px;
}

.sub-chip-right-icon {
  padding-top: 2px;
  padding-right: 3px;
  float: right;
}

.g-editor {
  border-radius: 15px;
}

.q-editor-toolbar {
  border-radius: 15px;
  background: transparent !important;
  border-bottom: none;
  opacity: 0.6;
}

.q-list--dark > .router-link-exact-active {
  background: #333333 !important;
}

.q-list--dark > .q-item-link:hover {
  background: #333333 !important;
}

.q-editor-toolbar-padding > .q-btn-group > .q-btn {
  border-radius: 15px;
}

.g-mark-color {
  background: #ff6600;
  border-radius: 3px;
  color: white;
}

.g-advanced-filter-col {
  min-height: 40px !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: #fff !important;
  background: #333333 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  color: #fff !important;
  background: #2b2b2b !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  background: #333333 !important;
  border: 1px solid #2b2b2b !important;
  color: #fff !important;
}

.skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(230, 230, 230, 0) 0, rgba(230, 230, 230, 0.2) 20%, rgba(230, 230, 230, 0.5) 60%, rgba(230, 230, 230, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-dark {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #333333;
}
.skeleton-dark::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(69, 69, 69, 0) 0, rgba(69, 69, 69, 0.2) 20%, rgba(69, 69, 69, 0.5) 60%, rgba(69, 69, 69, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vertical-line-long {
  padding: 10px 10px 10px 10px;
  border-left-width: 1px;
  border-left-style: solid;
  margin-right: -21px;
  margin-bottom: -30px;
  border-color: #dadada;
}

.q-card--dark .vertical-line-long {
  border-color: #454545;
}

.q-list--dark .q-item.active, .q-item--dark .q-item.active, .q-list--dark .q-item.router-link-active, .q-item--dark .q-item.router-link-active, .q-list--dark .q-item:focus, .q-item--dark .q-item:focus {
  background: #424242;
}

.bg--dark > .q-list > .q-item:hover {
  background: #424242;
}

.g-dashboard-card-text > p {
  margin: 0;
}

.is-internal {
  color: #000 !important;
  background: transparent !important;
  border: #ff6600 solid 2px;
}

.is-internal > div > span {
  opacity: 0.6;
}

.is-internal-dark {
  color: #ffffff !important;
  background: transparent !important;
  border: #ff6600 solid 2px;
}

.is-internal-dark > div > span {
  opacity: 0.6;
}

.q-notification > .q-alert {
  flex-wrap: wrap;
  width: 400px;
}

.q-notification > .q-alert > .q-alert-side {
  display: none;
}

@media (min-width 0) {
  .q-notification > .q-alert > .q-alert-content {
    height: auto;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.q-notification > .q-alert > .q-alert-actions {
  width: 350px;
  flex-direction: row;
  padding-top: 0;
  padding-left: 12px;
  margin: -12px 0 0 0;
}
@media (min-width 0) {
  .q-notification > .q-alert > .q-alert-actions {
    height: auto;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.q-notification > .q-alert > .q-alert-actions > div {
  width: auto !important;
}

.q-notification > .q-alert > .q-alert-actions > div > .q-btn {
  background: #fff;
  color: #ff6600;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 14px;
}

.g-table-fit-analytics-origin-urls {
  height: 350px;
}

.g-table-fit-analytics-origin-urls > .q-table-middle {
  width: 100%;
  height: 300px;
}

.g-table-fit-analytics-origin-urls > .q-table-bottom {
  width: 100%;
  border-top: none;
}

.save-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.save-btn button {
  font-weight: 300 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.g-includesDragBoard {
  overflow: hidden;
  border-radius: 11px;
}

.g-includesDragBoard .g-dragBoard {
  background: rgba(218, 218, 218, 0.8);
  filter: blur(50px);
  transition: all linear 0.2s;
}

.g-includesDragBoard:hover .g-dragBoard {
  filter: blur(90px);
}

.g-includesDragBoard .g-dragBoardBorder {
  width: calc(100% - 30px);
  float: left;
  border: 4px dashed #C4C4C4;
  margin: 15px;
}

.bg-fade {
  background: linear-gradient(90.15deg, rgba(0, 0, 0, 0.5) 0.14%, rgba(0, 0, 0, 0) 108.25%);
}

.bg-cover {
  background-size: cover;
}

.bg-norepeat {
  background-repeat: no-repeat;
}

.bg-positioncenter {
  background-position: center;
}

.formLoginHeight {
  height: calc(100vh - 78px) !important;
}

.bg-form {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(25px);
}

.inputFormLogin {
  padding-left: 5px;
  padding-right: 5px;
}

.menu-item .menu-icon {
  background-image: linear-gradient(to right, #f60, #f60);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: all 0.1s linear 0.3s;
}

.menu-item:hover .menu-icon {
  background-size: 100% 100%;
}

.menu-item .menu-label {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  margin-left: -120px;
  transition: all 0.3s linear 0.15s;
}

.menu-item:hover .menu-label {
  margin-left: 80px;
}

.menu-item .menu-label span {
  opacity: 0;
  transition: opacity 0.5s linear 0.1s;
}

.menu-item:hover .menu-label span {
  opacity: 1;
}

.listItem .q-hoverable:hover > .q-focus-helper {
  background: unset !important;
}

.knowledgeTable > .q-table__top {
  padding-left: 0;
  padding-right: 0;
}

.vertical-line-long {
  height: 85vh;
}